module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"D:\\GitHub\\timecode\\src\\components\\Layout\\Layout.js","injectPageProps":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Timecode Productions","short_name":"Timecode","start_url":"/","background_color":"#56483a","theme_color":"#56483a","display":"minimal-ui","icon":"src/images/timecode-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b13c4e3a376bea6c74e55d100271e882"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
