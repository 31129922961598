import {useState, useEffect} from "react";

export const CheckForScreens = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [screen, setScreen] = useState(null);

    function checker() {
        const phone = 480;
        const tablet = 860;
        const laptop = 992;
        const desktop = 1200;
        let windowWidth = document.documentElement.clientWidth;
        if (windowWidth < phone) {
            setIsMobile(true);
            setScreen('phone');
        } else if (windowWidth <= tablet) {
            setIsMobile(true);
            setScreen('tablet')
        } else if (windowWidth < laptop) {
            setIsMobile(false);
            setScreen('laptop')
        } else if (windowWidth >= desktop) {
            setIsMobile(false);
            setScreen('desktop')
        }
    }

    useEffect(() => {
        checker();
        window.addEventListener('resize', checker);
        return () => {
            checker();
            window.removeEventListener('resize', checker);
        };
    });

    return [isMobile, screen]
};

export const StringUtils = {
    joinIgnoreEmpty: (separator, ...strs) => {
        return strs.filter(Boolean).join(separator);
    },
};

export const ToggleShow = () => {
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);
    return [show, toggleShow];
};


export const goToUrl = (url, openInNewTab = false) => {
    if (url) {
        if (openInNewTab) {
            return window.open(url, '_blank');
        } else {
            return window.location.href = url;
        }
    }
};

export const TextAlign = (textAlign, textAlignMd, textAlignLg,) => {
    let text = "";
    text += (textAlign ? " text-" + textAlign : "");
    text += (textAlignMd ? " text-md-" + textAlignMd : "");
    text += (textAlignLg ? " text-lg-" + textAlignLg : "");
    return text;
};

export const Padding = ({p, pl, pr, px, pt, pb, py, pMd, plMd, prMd, pxMd, ptMd, pbMd, pyMd, pLg, plLg, prLg, pxLg, ptLg, pbLg, pyLg}) => {
    let padding = "";

    padding += (p || p === 0 ? " p-" + p : "");
    padding += (pl || pl === 0 ? " pl-" + pl : "");
    padding += (pr || pr === 0 ? " pr-" + pr : "");
    padding += (px || px === 0 ? " px-" + px : "");
    padding += (pt || pt === 0 ? " pt-" + pt : "");
    padding += (pb || pb === 0 ? " pb-" + pb : "");
    padding += (py || py === 0 ? " py-" + py : "");

    padding += (pMd || pMd === 0 ? " p-md-" + pMd : "");
    padding += (plMd || plMd === 0 ? " pl-md-" + plMd : "");
    padding += (prMd || prMd === 0 ? " pr-md-" + prMd : "");
    padding += (pxMd || pxMd === 0 ? " px-md-" + pxMd : "");
    padding += (ptMd || ptMd === 0 ? " pt-md-" + ptMd : "");
    padding += (pbMd || pbMd === 0 ? " pb-md-" + pbMd : "");
    padding += (pyMd || pyMd === 0 ? " py-md-" + pyMd : "");

    padding += (pLg || pLg === 0 ? " p-lg-" + pLg : "");
    padding += (plLg || plLg === 0 ? " pl-lg-" + plLg : "");
    padding += (prLg || prLg === 0 ? " pr-lg-" + prLg : "");
    padding += (pxLg || pxLg === 0 ? " px-lg-" + pxLg : "");
    padding += (ptLg || ptLg === 0 ? " pt-lg-" + ptLg : "");
    padding += (pbLg || pbLg === 0 ? " pb-lg-" + pbLg : "");
    padding += (pyLg || pyLg === 0 ? " py-lg-" + pyLg : "");

    return padding;
};


export const Margin = ({m, ml, mr, mx, mt, mb, my, mMd, mlMd, mrMd, mxMd, mtMd, mbMd, myMd, mLg, mlLg, mrLg, mxLg, mtLg, mbLg, myLg}) => {
    let margin = "";
    margin += (m || m === 0 ? " m-" + m : "");
    margin += (ml || ml === 0 ? " ml-" + ml : "");
    margin += (mr || mr === 0 ? " mr-" + mr : "");
    margin += (mx || mx === 0 ? " mx-" + mx : "");
    margin += (mt || mt === 0 ? " mt-" + mt : "");
    margin += (mb || mb === 0 ? " mb-" + mb : "");
    margin += (my || my === 0 ? " my-" + my : "");

    margin += (mMd || mMd === 0 ? " m-md-" + mMd : "");
    margin += (mlMd || mlMd === 0 ? " ml-md-" + mlMd : "");
    margin += (mrMd || mrMd === 0 ? " mr-md-" + mrMd : "");
    margin += (mxMd || mxMd === 0 ? " mx-md-" + mxMd : "");
    margin += (mtMd || mtMd === 0 ? " mt-md-" + mtMd : "");
    margin += (mbMd || mbMd === 0 ? " mb-md-" + mbMd : "");
    margin += (myMd || myMd === 0 ? " my-md-" + myMd : "");

    margin += (mLg || mLg === 0 ? " m-lg-" + mLg : "");
    margin += (mlLg || mlLg === 0 ? " ml-lg-" + mlLg : "");
    margin += (mrLg || mrLg === 0 ? " mr-lg-" + mrLg : "");
    margin += (mxLg || mxLg === 0 ? " mx-lg-" + mxLg : "");
    margin += (mtLg || mtLg === 0 ? " mt-lg-" + mtLg : "");
    margin += (mbLg || mbLg === 0 ? " mb-lg-" + mbLg : "");
    margin += (myLg || myLg === 0 ? " my-lg-" + myLg : "");

    return margin;
};

