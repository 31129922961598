export const evaIconsFill = {
    activity: {
        id: "activity", viewBox: "0 0 24 24",
        path: "M22,12c0,0.6-0.4,1-1,1h-2.3l-2.5,5.8c-0.3,0.7-1,1.2-1.8,1.2h-0.2c-0.9-0.1-1.6-0.7-1.8-1.6L9.7,6l-2.8,6.4C6.8,12.8,6.4,13,6,13H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.3l2.5-5.8c0.4-1,1.6-1.5,2.6-1c0.6,0.3,1,0.8,1.2,1.4L14.3,18l2.8-6.4c0.2-0.4,0.5-0.6,0.9-0.6h3C21.6,11,22,11.4,22,12z"
    },
    alert_circle: {
        id: "alert-circle", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,17c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S12.6,17,12,17z M13,13c0,0.6-0.4,1-1,1s-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1V13z"
    },
    alert_triangle: {
        id: "alert-triangle", viewBox: "0 0 24 24",
        path: "M22.6,16.3L14.9,3.6c-1-1.6-3.1-2.1-4.7-1c-0.4,0.3-0.8,0.6-1,1L1.4,16.3c-0.6,0.9-0.6,2.1,0,3C2,20.4,3.1,21,4.3,21h15.3c1.2,0,2.3-0.6,2.9-1.7C23.1,18.4,23.1,17.2,22.6,16.3z M12,17c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S12.6,17,12,17z M13,13c0,0.6-0.4,1-1,1s-1-0.4-1-1V9c0-0.6,0.4-1,1-1s1,0.4,1,1V13z"
    },
    archive: {
        id: "archive-outline", viewBox: "0 0 24 24",
        path: "M20.2,4c-0.6-0.6-1.4-1-2.2-1H6C4.3,3,3,4.4,3,6c0,0.8,0.4,1.6,1,2.2V18c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3V8.2C21.2,7.1,21.3,5.2,20.2,4z M15,13.1c0,0.5-0.4,0.9-0.9,0.9H9.9C9.4,14,9,13.6,9,13.1v-0.3C9,12.4,9.4,12,9.9,12h4.3c0.5,0,0.9,0.4,0.9,0.9V13.1z M18,7H6C5.4,7,5,6.6,5,6s0.4-1,1-1h12c0.6,0,1,0.4,1,1S18.6,7,18,7z"
    },
    arrow_down: {
        id: "arrow-down", viewBox: "0 0 24 24",
        path: "M17.5,11.3l-4.2,5.1C13,16.8,12.5,17,12,17c-0.5,0-1-0.2-1.3-0.6l-4.2-5.1C6,10.6,5.9,9.8,6.2,9c0.3-0.6,0.9-1,1.6-1h8.4c0.7,0,1.3,0.4,1.6,1C18.1,9.8,18,10.6,17.5,11.3z"
    },
    arrow_left: {
        id: "arrow-left", viewBox: "0 0 24 24",
        path: "M15.5,7.8v8.4c0,0.7-0.4,1.3-1,1.6c-0.3,0.1-0.6,0.2-0.9,0.2c-0.5,0-0.9-0.2-1.3-0.5l-5.1-4.2c-0.7-0.6-0.9-1.7-0.3-2.4c0.1-0.1,0.2-0.2,0.3-0.3l5.1-4.2c0.6-0.5,1.5-0.6,2.2-0.3C15.1,6.5,15.5,7.1,15.5,7.8z"
    },
    arrow_right: {
        id: "arrow-right", viewBox: "0 0 24 24",
        path: "M17.1,13.1c-0.1,0.1-0.2,0.2-0.3,0.3l-5.1,4.2c-0.4,0.3-0.8,0.5-1.3,0.5c-0.3,0-0.6-0.1-0.9-0.2c-0.6-0.3-1-0.9-1-1.6V7.8c0-0.7,0.4-1.3,1-1.6c0.7-0.3,1.6-0.2,2.2,0.3l5.1,4.2C17.6,11.3,17.7,12.3,17.1,13.1z"
    },
    arrow_up: {
        id: "arrow-up", viewBox: "0 0 24 24",
        path: "M17.8,15c-0.3,0.6-0.9,1-1.6,1H7.8c-0.7,0-1.3-0.4-1.6-1c-0.3-0.7-0.2-1.6,0.3-2.2l4.2-5.1c0.6-0.7,1.7-0.8,2.5-0.2c0.1,0.1,0.1,0.1,0.2,0.2l4.2,5.1C18,13.4,18.1,14.3,17.8,15z"
    },
    arrow_line_down: {
        id: "arrow-line-down", viewBox: "0 0 24 24",
        path: "M18.6,14.8l-6,5l-0.2,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0l-0.1-0.1l-0.2-0.1l-6-5c-0.4-0.4-0.5-1-0.1-1.4c0.4-0.4,1-0.5,1.4-0.1l4.4,3.6V5c0-0.6,0.4-1,1-1s1,0.4,1,1v11.9l4.4-3.6c0,0,0,0,0,0c0.4-0.4,1.1-0.3,1.4,0.1c0,0,0,0,0,0C19.1,13.8,19.1,14.4,18.6,14.8z"
    },
    arrow_line_left: {
        id: "arrow-line-left", viewBox: "0 0 24 24",
        path: "M20,12c0,0.6-0.4,1-1,1H7.1l3.6,4.4c0,0,0,0,0,0c0.4,0.4,0.3,1.1-0.1,1.4C10.5,18.9,10.2,19,10,19c-0.3,0-0.6-0.1-0.8-0.4l-5-6c0,0-0.1-0.1-0.1-0.2c-0.1,0-0.1-0.1-0.1-0.1C4,12.2,4,12.1,4,12c0-0.1,0-0.2,0.1-0.4c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2l5-6c0.4-0.4,1-0.5,1.4-0.1c0.4,0.4,0.5,1,0.1,1.4L7.1,11H19C19.6,11,20,11.4,20,12z"
    },
    arrow_line_right: {
        id: "arrow-line-right", viewBox: "0 0 24 24",
        path: "M20,12c0,0.1,0,0.2-0.1,0.4c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2l-5,6c-0.4,0.4-1,0.5-1.4,0.1c-0.4-0.4-0.5-1-0.1-1.4l3.6-4.4H5c-0.6,0-1-0.4-1-1s0.4-1,1-1h11.9l-3.6-4.4c0,0,0,0,0,0c-0.4-0.4-0.3-1.1,0.1-1.4C13.5,5.1,13.8,5,14,5c0.3,0,0.6,0.1,0.8,0.4l5,6c0,0,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1C20,11.8,20,11.9,20,12z"
    },
    arrow_line_up: {
        id: "arrow-line-up", viewBox: "0 0 24 24",
        path: "M18.8,10.6c-0.4,0.4-1,0.5-1.4,0.1L13,7.1V19c0,0.6-0.4,1-1,1s-1-0.4-1-1V7.1l-4.4,3.6c0,0,0,0,0,0c-0.4,0.4-1.1,0.3-1.4-0.1c0,0,0,0,0,0c-0.4-0.4-0.3-1.1,0.1-1.4l6-5l0.2-0.1l0.1-0.1c0.2-0.1,0.5-0.1,0.7,0l0.1,0.1l0.2,0.1l6,5C19.1,9.6,19.1,10.2,18.8,10.6z"
    },
    arrow_circle_down: {
        id: "arrow-circle-down", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M15.7,13.9l-3,2.9c0,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0.1-0.8,0c-0.1,0-0.2-0.1-0.3-0.2l-3-3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.3,1.3V8c0-0.6,0.4-1,1-1s1,0.4,1,1v5.7l1.3-1.3c0.4-0.4,1-0.4,1.4,0C16.1,12.8,16.1,13.5,15.7,13.9z"
    },
    arrow_circle_left: {
        id: "arrow-circle-left", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16,13h-5.7l1.3,1.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-2.9-3c0,0-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.2c-0.1-0.2-0.1-0.5,0-0.8c0-0.1,0.1-0.2,0.2-0.3l3-3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L10.4,11H16c0.6,0,1,0.4,1,1S16.6,13,16,13z"
    },
    arrow_circle_right: {
        id: "arrow-circle-right", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.9,12.4c0,0.1-0.1,0.2-0.2,0.3l-3,3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l1.3-1.3H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h5.7l-1.3-1.3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.9,3c0,0,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.2C17,11.9,17,12.1,16.9,12.4z"
    },
    arrow_circle_up: {
        id: "arrow-circle-up", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M15.7,11.7c-0.4,0.4-1,0.4-1.4,0L13,10.4V16c0,0.6-0.4,1-1,1s-1-0.4-1-1v-5.7l-1.3,1.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l3-2.9c0,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2-0.1c0.2-0.1,0.5-0.1,0.8,0c0.1,0,0.2,0.1,0.3,0.2l3,3C16.1,10.7,16.1,11.3,15.7,11.7z"
    },
    arrow_head_down: {
        id: "arrow-head-down", viewBox: "0 0 24 24",
        path: "M18.8,12.5c0.3,0.4,0.3,1.1-0.2,1.4l-6,4.8c-0.4,0.3-0.9,0.3-1.3,0l-6-5c-0.4-0.4-0.5-1-0.1-1.4c0.4-0.4,1-0.5,1.4-0.1l5.4,4.5l5.4-4.3c0,0,0,0,0,0C17.8,12,18.4,12.1,18.8,12.5C18.8,12.5,18.8,12.5,18.8,12.5z M11.4,11.8c0.4,0.3,0.9,0.3,1.3,0l6-4.8c0.4-0.3,0.5-1,0.2-1.4c0,0,0,0,0,0c-0.3-0.4-1-0.5-1.4-0.2c0,0,0,0,0,0L12,9.7L6.6,5.2C6.2,4.9,5.6,4.9,5.2,5.4C4.9,5.8,4.9,6.4,5.4,6.8L11.4,11.8z"
    },
    arrow_head_left: {
        id: "arrow-head-left", viewBox: "0 0 24 24",
        path: "M11.8,6.6L7.3,12l4.3,5.4c0.1,0.2,0.2,0.4,0.2,0.6c0,0.6-0.5,1-1,1c-0.3,0-0.6-0.1-0.8-0.4l-4.8-6c-0.3-0.4-0.3-0.9,0-1.3l5-6c0,0,0,0,0,0c0.4-0.4,1-0.5,1.4-0.1C12.1,5.6,12.1,6.2,11.8,6.6z M14.3,12l4.5-5.4c0.4-0.4,0.3-1.1-0.1-1.4c-0.4-0.4-1.1-0.3-1.4,0.1l-5,6c-0.3,0.4-0.3,0.9,0,1.3l4.8,6c0.2,0.2,0.5,0.4,0.8,0.4c0.6,0,1-0.4,1-1c0-0.2-0.1-0.5-0.2-0.6L14.3,12z"
    },
    arrow_head_right: {
        id: "arrow-head-right", viewBox: "0 0 24 24",
        path: "M18.8,12.6l-5,6C13.6,18.9,13.3,19,13,19c-0.2,0-0.5-0.1-0.6-0.2c0,0,0,0,0,0c-0.4-0.4-0.5-1-0.1-1.4l4.5-5.4l-4.3-5.4c0,0,0,0,0,0c-0.3-0.4-0.3-1.1,0.2-1.4c0,0,0,0,0,0C13,4.9,13.7,4.9,14,5.4l4.8,6C19.1,11.7,19.1,12.3,18.8,12.6z M7,5.4C6.7,4.9,6,4.8,5.6,5.2S5,6.2,5.4,6.6L9.7,12l-4.5,5.4c-0.4,0.4-0.3,1.1,0.1,1.4c0,0,0,0,0,0C5.5,18.9,5.8,19,6,19c0.3,0,0.6-0.1,0.8-0.4l5-6c0.3-0.4,0.3-0.9,0-1.3L7,5.4z"
    },
    arrow_head_up: {
        id: "arrow-head-up", viewBox: "0 0 24 24",
        path: "M5.2,11.5c-0.3-0.4-0.3-1.1,0.2-1.4l6-4.8c0.4-0.3,0.9-0.3,1.3,0l6,5c0,0,0,0,0,0c0.4,0.4,0.5,1,0.1,1.4C18.6,11.9,18.3,12,18,12c-0.2,0-0.5-0.1-0.6-0.2L12,7.3l-5.4,4.3c0,0,0,0,0,0C6.2,12,5.6,11.9,5.2,11.5C5.2,11.5,5.2,11.5,5.2,11.5z M18.6,17.2l-6-5c-0.4-0.3-0.9-0.3-1.3,0l-6,4.8c-0.4,0.3-0.5,1-0.2,1.4c0,0,0,0,0,0c0.3,0.4,1,0.5,1.4,0.2c0,0,0,0,0,0l5.4-4.3l5.4,4.5c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4C19.1,18.2,19.1,17.6,18.6,17.2C18.6,17.2,18.6,17.2,18.6,17.2z"
    },
    arrow_ios_down: {
        id: "arrow-ios-down", viewBox: "0 0 24 24",
        path: "M18.7,11C18.7,11,18.7,11,18.7,11l-6.1,4.9C12.4,16,12.2,16,12,16c-0.2,0-0.5-0.1-0.6-0.2l-6-5c-0.4-0.4-0.5-1-0.1-1.4c0.4-0.4,1-0.5,1.4-0.1l5.4,4.5l5.4-4.3c0.4-0.3,1.1-0.3,1.4,0.1c0,0,0,0,0,0C19.1,10,19.1,10.6,18.7,11z"
    },
    arrow_ios_left: {
        id: "arrow-ios-left", viewBox: "0 0 24 24",
        path: "M14.6,17.4c0.4,0.4,0.3,1.1-0.1,1.4c-0.2,0.2-0.4,0.2-0.7,0.2c-0.3,0-0.6-0.1-0.8-0.4l-4.8-6c-0.3-0.4-0.3-0.9,0-1.3l5-6c0.4-0.4,1-0.5,1.4-0.1c0.4,0.4,0.5,1,0.1,1.4L10.3,12L14.6,17.4z"
    },
    arrow_ios_right: {
        id: "arrow-ios-right", viewBox: "0 0 24 24",
        path: "M15.8,12.6l-5,6C10.6,18.9,10.3,19,10,19c-0.2,0-0.5-0.1-0.6-0.2c-0.4-0.4-0.5-1-0.1-1.4c0,0,0,0,0,0l4.5-5.4L9.4,6.6C9,6.2,9.1,5.6,9.5,5.2c0,0,0,0,0,0c0.4-0.4,1-0.3,1.4,0.1c0,0,0,0,0,0.1l4.8,6C16.1,11.7,16.1,12.3,15.8,12.6z"
    },
    arrow_ios_up: {
        id: "arrow-ios-up", viewBox: "0 0 24 24",
        path: "M18.8,14.6C18.8,14.6,18.8,14.6,18.8,14.6C18.6,14.9,18.3,15,18,15c-0.2,0-0.5-0.1-0.6-0.2L12,10.3l-5.4,4.3c-0.4,0.3-1.1,0.3-1.4-0.1c0,0,0,0,0,0c-0.3-0.4-0.3-1.1,0.1-1.4c0,0,0,0,0,0l6-4.8c0.4-0.3,0.9-0.3,1.3,0l6,5C19.1,13.6,19.1,14.2,18.8,14.6z"
    },
    at: {
        id: "at", viewBox: "0 0 24 24",
        path: "M13,2C7.5,1.5,2.6,5.5,2.1,11c-0.4,4.3,2,8.4,5.9,10.1c1.3,0.6,2.6,0.9,4,0.9c2.2,0,4.3-0.7,6.1-2.1c0.4-0.3,0.5-1,0.2-1.4c-0.3-0.4-1-0.5-1.4-0.2c0,0,0,0,0,0c-3.5,2.7-8.5,2-11.2-1.6C3,13.3,3.7,8.2,7.2,5.6c1.6-1.2,3.6-1.8,5.6-1.6c4.2,0.5,7.3,4,7.2,8.2v0.7c0,0.9-0.8,1.7-1.7,1.7c0,0,0,0-0.1,0c-0.9-0.1-1.7-0.9-1.6-1.9V8.4c0-0.6-0.4-1-1-1c-0.5,0-0.9,0.4-1,0.9c-0.9-0.9-2.2-1.4-3.4-1.4c-2.8,0-5.1,2.3-5.1,5.1c0,2.8,2.3,5.1,5.1,5.1c1.6,0,3.1-0.8,4.1-2.1c1.1,1.7,3.3,2.1,5,1.1c0.2-0.1,0.4-0.3,0.6-0.5c0.7-0.7,1.2-1.7,1.1-2.7v-0.7C22,7,18.2,2.6,13,2z M11.2,15.1c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1C14.3,13.7,12.9,15.1,11.2,15.1z"
    },
    attach: {
        id: "attach", viewBox: "0 0 24 24",
        path: "M18,6.1v10.2c-0.1,3.2-2.8,5.8-6,5.7c-3.2,0.1-5.9-2.5-6-5.7V6.1C6.1,3.8,8,1.9,10.3,2c2.3-0.1,4.3,1.8,4.3,4.1v10.2c-0.1,1.5-1.4,2.6-2.8,2.5c-1.3-0.1-2.4-1.2-2.5-2.5V6.9c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v9.4c0,0.4,0.4,0.6,0.7,0.6c0.3,0,0.5-0.3,0.6-0.6V6.1c-0.1-1.2-1.1-2.2-2.3-2.1C9.1,4,8.1,4.9,8,6.1v10.2c0.1,2.1,1.9,3.8,4,3.7c2.1,0.1,3.9-1.6,4-3.7V6.1c0-0.6,0.4-1,1-1S18,5.6,18,6.1z"
    },
    attach45: {
        id: "attach-45", viewBox: "0 0 24 24",
        path: "M20.7,11.9l-7.4,7.4c-1.1,1.1-2.5,1.7-4,1.7c-1.7,0-3.3-0.7-4.4-1.9c-2.4-2.3-2.5-6.1-0.2-8.5c0,0,0,0,0,0L12,3.2C12.8,2.4,13.9,2,15,2c1.2,0,2.4,0.5,3.2,1.3C19.9,5,20,7.7,18.3,9.5l-7.4,7.4c-0.5,0.5-1.1,0.8-1.8,0.8c-0.7,0-1.4-0.3-2-0.8c-1.1-1-1.1-2.7-0.1-3.8l6.8-6.9C14.4,5.9,15,6,15.3,6.5c0.2,0.3,0.2,0.8,0,1.1l-6.8,6.9c-0.2,0.3-0.2,0.7,0.1,1c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3-0.1,0.4-0.2l7.4-7.4c0.9-1,0.8-2.4-0.1-3.3c-0.9-0.9-2.3-1-3.3-0.2L6.1,12c-1.5,1.6-1.4,4.1,0.2,5.7c0,0,0,0,0,0c0.8,0.8,1.9,1.3,3,1.3c1,0,1.9-0.4,2.6-1.1l7.4-7.4c0.4-0.4,1-0.4,1.4,0C21.1,10.9,21.1,11.5,20.7,11.9z"
    },
    award: {
        id: "award", viewBox: "0 0 24 24",
        path: "M19,20.8l-2.3-9C17.5,10.7,18,9.4,18,8c0-3.3-2.7-6-6-6S6,4.7,6,8c0,1.4,0.5,2.7,1.3,3.8l-2.3,9c-0.1,0.5,0.2,1.1,0.7,1.2C6,22,6.3,22,6.5,21.9l5.3-3.1l5.7,3.1C17.6,22,17.8,22,18,22c0.6,0,1-0.4,1-1C19,20.9,19,20.8,19,20.8z M12,12c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S14.2,12,12,12z"
    },

    backspace: {
        id: "backspace", viewBox: "0 0 24 24",
        path: "M20.1,4C20.1,4,20.1,4,20.1,4h-9.8c-0.7,0-1.5,0.3-2,0.8L8.3,4.9l-6,7.5c-0.3,0.4-0.3,1,0.1,1.4l6,5.5c0.5,0.5,1.3,0.8,2,0.8h9.8c1,0,1.8-0.8,1.8-1.8V5.8C22,4.8,21.2,4,20.1,4z M16.7,14.7C16.7,14.7,16.7,14.7,16.7,14.7c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L14,13.4l-1.3,1.3c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l1.3-1.3l-1.3-1.3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.3,1.3l1.3-1.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L15.4,12l1.3,1.3C17.1,13.7,17.1,14.3,16.7,14.7z"
    },
    bar_chart: {
        id: "bar-chart", viewBox: "0 0 24 24",
        path: "M13,9v11c0,0.6-0.4,1-1,1s-1-0.4-1-1V9c0-0.6,0.4-1,1-1S13,8.4,13,9z M19,4c-0.6,0-1,0.4-1,1v15c0,0.6,0.4,1,1,1s1-0.4,1-1V5C20,4.4,19.6,4,19,4z M5,12c-0.6,0-1,0.4-1,1v7c0,0.6,0.4,1,1,1s1-0.4,1-1v-7C6,12.4,5.6,12,5,12z"
    },
    bar_chart_2: {
        id: "bar-chart-2", viewBox: "0 0 24 24",
        path: "M13,5v15c0,0.6-0.4,1-1,1s-1-0.4-1-1V5c0-0.6,0.4-1,1-1S13,4.4,13,5z M19,12c-0.6,0-1,0.4-1,1v7c0,0.6,0.4,1,1,1s1-0.4,1-1v-7C20,12.4,19.6,12,19,12z M5,8C4.4,8,4,8.4,4,9v11c0,0.6,0.4,1,1,1s1-0.4,1-1V9C6,8.4,5.6,8,5,8z"
    },
    battery: {
        id: "battery", viewBox: "0 0 24 24",
        path: "M18,8.4v7.1c0.1,1.3-0.9,2.4-2.2,2.4H4.2c-1.3-0.1-2.2-1.2-2.2-2.4V8.4C1.9,7.2,2.9,6.1,4.2,6h11.7C17.1,6.1,18.1,7.2,18,8.4z M21,9c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4C22,9.4,21.6,9,21,9z"
    },
    battery_charging: {
        id: "battery-charging", viewBox: "0 0 24 24",
        path: "M11.3,13l-2.9,5H4.2c-1.3-0.1-2.2-1.2-2.2-2.4V8.4C1.9,7.2,2.9,6.1,4.2,6h5.1l-3.1,5.5c-0.2,0.3-0.2,0.7,0,1C6.3,12.8,6.6,13,7,13H11.3z M15.8,6h-4.3l-2.9,5H13c0.4,0,0.7,0.2,0.9,0.5c0.2,0.3,0.2,0.7,0,1L10.7,18h5.1c1.3-0.1,2.2-1.2,2.2-2.4V8.4C18.1,7.2,17.1,6.1,15.8,6z M21,9c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4C22,9.4,21.6,9,21,9z"
    },
    behance: {
        id: "behance", viewBox: "0 0 24 24",
        path: "M15.8,12.3h-2.1c0,0,0-0.1,0-0.1c0-0.6,0.5-1,1-1c0.1,0,0.1,0,0.2,0C15.5,11.2,15.9,11.7,15.8,12.3z M9.5,12.3H8.3v1.9h1c1,0,1.4-0.3,1.4-1S10.2,12.3,9.5,12.3z M10.4,10.5c0-0.5-0.3-0.9-1-0.9H8.3v1.7h0.8C10,11.4,10.4,11.1,10.4,10.5z M22,12c0,5.5-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2S22,6.5,22,12z M13.3,9.7h3V9.2h-3V9.7z M12,13.3c0-0.8-0.6-1.5-1.5-1.6c0.7-0.1,1.1-0.7,1.1-1.4c0-1-0.8-1.6-1.9-1.6H7v6.5h2.7C11,15.2,12,14.5,12,13.3C12,13.3,12,13.3,12,13.3z M17,12.3c-0.1-1.2-1.1-2.1-2.3-2c-0.1,0-0.2,0-0.3,0c-1.2,0.1-2,1.1-2,2.3V13c0,0.1,0,0.3,0,0.4c0.1,1.2,1.2,2,2.4,1.9c1,0.1,1.9-0.6,2.2-1.5h-1.2c-0.1,0.4-0.6,0.7-1,0.6c0,0-0.1,0-0.1,0c-0.6-0.1-1-0.6-0.9-1.2V13H17v-0.5C17,12.5,17,12.4,17,12.3z"
    },
    bell_off: {
        id: "bell-off", viewBox: "0 0 24 24",
        path: "M15.3,18.1L14,16.8l-0.1-0.1L6.6,9.4L5.3,8.1c0,0.2,0,0.4,0,0.6v4.7l-1.8,1.8c-0.6,0.6-0.6,1.7,0,2.3C3.8,17.8,4.2,18,4.6,18H8v0.3c0.1,2.1,1.9,3.8,4,3.7c2,0.1,3.7-1.3,4-3.2l-0.1-0.1L15.3,18.1z M14,18.3c-0.1,1-1,1.7-2,1.7c-1,0.1-1.9-0.7-2-1.7V18h4V18.3z M7.1,4.3L7,4.2C7.2,4,7.4,3.8,7.6,3.7C9,2.4,11,1.8,12.9,2.1c3.4,0.5,5.9,3.5,5.8,6.9v4.5l1.8,1.8c0.5,0.5,0.6,1.2,0.3,1.8c-0.1,0.2-0.3,0.4-0.5,0.6l-0.3-0.3l-2-2L8.6,5.8L7.1,4.3z M20.7,20.7C20.7,20.7,20.7,20.7,20.7,20.7c-0.4,0.4-1,0.4-1.4,0L16.6,18L16,17.4l-1.3-1.3L14.6,16L7.3,8.7L5.5,7L3.3,4.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0L6.4,5l1.5,1.5l9.5,9.5l2,2l1.3,1.3c0,0,0,0,0,0C21.1,19.7,21.1,20.3,20.7,20.7z"
    },
    bell: {
        id: "bell", viewBox: "0 0 24 24",
        path: "M20.5,15.2l-1.8-1.8V8.9c0-3.4-2.4-6.4-5.8-6.9C9.2,1.6,5.8,4.2,5.3,7.9c0,0.3-0.1,0.6-0.1,0.9v4.7l-1.8,1.8c-0.6,0.6-0.6,1.7,0,2.3C3.8,17.8,4.2,18,4.6,18H8v0.3c0.1,2.1,1.9,3.8,4,3.7c2.1,0.1,3.9-1.5,4-3.7V18h3.4c0.9,0,1.6-0.7,1.6-1.6C21,15.9,20.8,15.5,20.5,15.2z M14,18.3c-0.1,1-1,1.7-2,1.7c-1,0.1-1.9-0.7-2-1.7V18h4V18.3z"
    },
    bluetooth: {
        id: "bluetooth", viewBox: "0 0 24 24",
        path: "M13.6,12l4-3.8c0.5-0.4,0.5-1.2,0.1-1.6c-0.1-0.1-0.1-0.1-0.2-0.2l-4.7-3.2C12.5,3,12,2.9,11.6,3.1c-0.4,0.2-0.6,0.6-0.6,1v6.2l-3.2-4c-0.4-0.4-1-0.5-1.4-0.1c-0.4,0.4-0.5,1-0.1,1.4L9.7,12l-3.5,4.4c-0.3,0.4-0.3,1.1,0.2,1.4C6.6,18,6.8,18,7,18c0.3,0,0.6-0.1,0.8-0.4l3.2-4.1v6.3c0,0.6,0.5,1.2,1.2,1.2c0.2,0,0.5-0.1,0.7-0.2l4.6-3.2c0.3-0.2,0.5-0.5,0.5-0.8c0-0.3-0.1-0.7-0.3-0.9L13.6,12z M13,5.8l2.5,1.7L13,9.8V5.8z M13,18.3v-4.1l2.5,2.4L13,18.3z"
    },
    book_open: {
        id: "book-open", viewBox: "0 0 24 24",
        path: "M21.5,5.3V17c0,0.6-0.4,1.1-0.9,1.2L13,20.2V5.9l6.9-1.8c0.4-0.1,0.8,0,1.1,0.2C21.3,4.6,21.5,4.9,21.5,5.3z M3,4.3C2.7,4.6,2.5,5,2.5,5.3V17c0,0.6,0.4,1.1,0.9,1.2l7.5,1.9V5.9L4.1,4.1C3.7,4,3.3,4.1,3,4.3z"
    },
    book: {
        id: "book", viewBox: "0 0 24 24",
        path: "M19,3H7C5.3,3,4,4.3,4,6v12c0,1.7,1.3,3,3,3h12c0.6,0,1-0.4,1-1V4C20,3.4,19.6,3,19,3z M18,19H7c-0.6,0-1-0.4-1-1s0.4-1,1-1h11V19z"
    },
    bookmark: {
        id: "bookmark", viewBox: "0 0 24 24",
        path: "M19,5.3V20c0,0.4-0.2,0.7-0.5,0.9c-0.3,0.2-0.7,0.2-1,0l-5.7-3.2l-5.3,3.2C6.3,20.9,6.2,21,6,21c-0.2,0-0.3,0-0.5-0.1C5.2,20.7,5,20.4,5,20V5.3C5,4.1,6,3,7.2,3h9.6C18,3,19,4.1,19,5.3z"
    },
    briefcase: {
        id: "briefcase", viewBox: "0 0 24 24",
        path: "M16,5.5C16,4.1,14.9,3,13.5,3h-3C9.1,3,8,4.1,8,5.5V7H7v14h10V7h-1V5.5z M14,7h-4V5.5C10,5.2,10.2,5,10.5,5h3C13.8,5,14,5.2,14,5.5V7z M22,10v8c0,1.7-1.3,3-3,3V7C20.7,7,22,8.3,22,10z M5,7v14c-1.7,0-3-1.3-3-3v-8C2,8.3,3.3,7,5,7z"
    },
    browser: {
        id: "browser", viewBox: "0 0 24 24",
        path: "M18,3H6C4.3,3,3,4.3,3,6v12c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V6C21,4.3,19.7,3,18,3z M12,6c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S11.4,6,12,6z M8,6c0.6,0,1,0.4,1,1S8.6,8,8,8S7,7.6,7,7S7.4,6,8,6z M19,18c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1v-7h14V18z"
    },
    brush: {
        id: "brush", viewBox: "0 0 24 24",
        path: "M11.2,17.7c-0.6,1.3-2,2.2-3.4,2.2L4.5,20c0,0,0,0,0,0C4.3,20,4,19.8,4,19.5v-3.1c0-1.8,1.3-3.4,3.1-3.9c0.8-0.2,1.7-0.1,2.5,0.3C11.4,13.7,12.1,15.9,11.2,17.7z M19.3,4.5c-0.9-0.7-2.1-0.6-2.9,0.2L10,11.1c-0.2,0.2-0.2,0.5,0,0.7l2.3,2.3c0.2,0.2,0.5,0.2,0.7,0l6.5-6.5C19.8,7.1,20,6.6,20,6.1C20,5.4,19.7,4.8,19.3,4.5z"
    },
    bulb: {
        id: "bulb", viewBox: "0 0 24 24",
        path: "M16,9c1.7,2.2,1.2,5.3-1,7v4c0,1.1-0.9,2-2,2h-2c-1.1,0-2-0.9-2-2v-4c-1.3-0.9-2-2.4-2-4c0-2.8,2.2-5,5-5C13.6,7,15.1,7.7,16,9z M12,6c0.6,0,1-0.4,1-1V3c0-0.6-0.4-1-1-1s-1,0.4-1,1v2C11,5.6,11.4,6,12,6z M21,11h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1-0.4,1-1S21.6,11,21,11z M5,11H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1-0.4,1-1S5.6,11,5,11z M7.7,7.8c0.4-0.4,0.3-1-0.1-1.4L6.2,5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4C6.5,8,6.7,8.1,7,8.1C7.3,8.1,7.5,8,7.7,7.8zM19.2,5.1C19.2,5.1,19.2,5.1,19.2,5.1c-0.4-0.4-1-0.4-1.4,0l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l1.4-1.4C19.6,6.1,19.6,5.4,19.2,5.1z"
    },

    calendar: {
        id: "calendar", viewBox: "0 0 24 24",
        path: "M18,4h-1V3c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H9V3c0-0.6-0.4-1-1-1S7,2.4,7,3v1H6C4.3,4,3,5.3,3,7v12c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V7C21,5.3,19.7,4,18,4z M8,17c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S8.6,17,8,17z M16,17h-4c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S16.6,17,16,17z M19,11H5V7c0-0.6,0.4-1,1-1h1v1c0,0.6,0.4,1,1,1s1-0.4,1-1V6h6v1c0,0.6,0.4,1,1,1s1-0.4,1-1V6h1c0.6,0,1,0.4,1,1V11z"
    },
    camera: {
        id: "camera", viewBox: "0 0 24 24",
        path: "M13.5,14c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5S13.5,13.2,13.5,14z M22,10v8c0,1.7-1.3,3-3,3H5c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h3V5.5C8,4.1,9.1,3,10.5,3h3C14.9,3,16,4.1,16,5.5V7h3C20.7,7,22,8.3,22,10z M10,7h4V5.5C14,5.2,13.8,5,13.5,5h-3C10.2,5,10,5.2,10,5.5V7z M15.5,14c0-1.9-1.6-3.5-3.5-3.5S8.5,12.1,8.5,14s1.6,3.5,3.5,3.5S15.5,15.9,15.5,14z"
    },
    car: {
        id: "car", viewBox: "0 0 24 24",
        path: "M21.6,11.2L17,7.5V5c0.1-1.1-0.8-1.9-1.8-2H3.8C2.7,3.1,1.9,4,2,5v10c0,0.8,0.5,1.6,1.2,1.9c-0.6,1.5,0.1,3.3,1.6,3.9c1.5,0.6,3.3-0.1,3.9-1.6c0.3-0.7,0.3-1.5,0-2.2h6.4c-0.6,1.6,0.2,3.3,1.8,3.8c1.6,0.6,3.3-0.2,3.8-1.8c0.2-0.7,0.2-1.4,0-2H21c0.6,0,1-0.4,1-1v-4C22,11.7,21.8,11.4,21.6,11.2z M6,19c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S6.6,19,6,19z M18,19c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S18.6,19,18,19z M20,15h-3v-4.9l3,2.4V15z"
    },
    cast: {
        id: "cast", viewBox: "0 0 24 24",
        path: "M21,5.8v12.4c0,1.5-1.1,2.7-2.6,2.8H17c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.4c0.4-0.1,0.6-0.4,0.6-0.8V5.8c0-0.4-0.2-0.7-0.6-0.8H5.6C5.2,5.1,5,5.4,5,5.8V7c0,0.6-0.4,1-1,1S3,7.6,3,7V5.8C3,4.3,4.1,3.1,5.6,3h12.8C19.9,3.1,21,4.3,21,5.8z M6.4,14.4C5.6,14,4.7,13.9,3.9,14c-0.5,0.1-0.9,0.6-0.9,1.1c0,0,0,0,0,0.1c0.1,0.5,0.6,0.9,1.1,0.8c0.8-0.1,1.6,0.2,2.1,0.7c0.7,0.7,1,1.9,0.5,2.8c-0.2,0.5,0,1.1,0.5,1.3C7.4,20.9,7.5,21,7.7,21c0.4,0,0.7-0.2,0.9-0.6C9.6,18.1,8.7,15.4,6.4,14.4zM7.3,10.3C6.2,10,5,9.9,3.9,10.1h0c-0.6,0.1-0.9,0.6-0.9,1.1c0.1,0.6,0.6,0.9,1.1,0.9c1.9-0.3,3.8,0.4,5.1,1.7c1.6,1.6,2.2,3.8,1.5,6c-0.2,0.5,0.1,1.1,0.7,1.3c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0.5,0,0.9-0.3,1-0.7C14,16,11.6,11.6,7.3,10.3zM4,18c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S4.6,18,4,18z"
    },
    check: {
        id: "check", viewBox: "0 0 24 24",
        path: "M19.8,7.6C19.8,7.6,19.8,7.6,19.8,7.6l-9.2,10.1c-0.2,0.2-0.5,0.3-0.7,0.3h0c-0.3,0-0.5-0.1-0.7-0.3l-4.9-5.2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l4.1,4.4l8.4-9.2c0.3-0.4,1-0.5,1.4-0.2C20.1,6.5,20.2,7.2,19.8,7.6z"
    },
    check_circle: {
        id: "check-circle", viewBox: "0 0 24 24",
        path: "M12,13.5l6.2-7.2c0.4-0.4,0.9-0.4,1.3-0.1c0.4,0.3,0.5,1,0.2,1.4l-7,8C12.5,15.9,12.3,16,12,16c-0.3,0-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0L12,13.5z M21,11c-0.6,0-1,0.4-1,1c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8c0-2.1,0.8-4.1,2.3-5.6C7.8,4.8,9.9,4,12,4c0.6,0,1.3,0.1,1.9,0.2c0.5,0.2,1.1-0.1,1.3-0.7C15.3,3,15,2.5,14.5,2.3c0,0-0.1,0-0.1,0C13.6,2.1,12.8,2,12,2C6.5,2,2,6.5,2.1,12.1c0,2.6,1.1,5.2,2.9,7c1.9,1.9,4.4,2.9,7,2.9c5.5,0,10-4.5,10-10C22,11.4,21.6,11,21,11z"
    },
    check_circle_empty: {
        id: "check-circle-empty", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20z"
    },
    check_circle_2: {
        id: "check-circle-2", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.3,9.6L16.3,9.6l-4.6,6c-0.2,0.2-0.5,0.4-0.8,0.4c-0.3,0-0.6-0.1-0.8-0.4l-2.4-3.1c-0.3-0.4-0.3-1.1,0.2-1.4c0.4-0.3,1.1-0.3,1.4,0.2l1.6,2.1l3.8-5c0.3-0.4,1-0.5,1.4-0.2C16.6,8.5,16.6,9.1,16.3,9.6z"
    },
    check_square: {
        id: "check-square", viewBox: "0 0 24 24",
        path: "M21,12.8v5.6c0,1.4-1.2,2.6-2.6,2.6H5.6C4.2,21,3,19.8,3,18.4V5.6C3,4.2,4.2,3,5.6,3h9.6c0.6,0,1,0.4,1,1s-0.4,1-1,1H5.6C5.3,5,5,5.3,5,5.6v12.8C5,18.7,5.3,19,5.6,19h12.8c0.3,0,0.6-0.3,0.6-0.6v-5.6c0-0.6,0.4-1,1-1S21,12.3,21,12.8z M10.7,11c-0.4-0.4-1-0.3-1.4,0.1C9,11.4,9,12,9.3,12.4l2.2,2.3c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l6.8-7c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-6.1,6.3L10.7,11z"
    },
    check_square_2: {
        id: "check-square-2", viewBox: "0 0 24 24",
        path: "M18,3H6C4.3,3,3,4.3,3,6v12c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V6C21,4.3,19.7,3,18,3z M16.3,9.6L16.3,9.6l-4.6,6c-0.2,0.2-0.5,0.4-0.8,0.4c-0.3,0-0.6-0.1-0.8-0.4l-2.4-3.1c-0.3-0.4-0.3-1.1,0.2-1.4c0.4-0.3,1.1-0.3,1.4,0.2l1.6,2.1l3.8-5c0.3-0.4,1-0.5,1.4-0.2C16.6,8.5,16.6,9.1,16.3,9.6z"
    },
    chevron_down: {
        id: "chevron-down", viewBox: "0 0 24 24",
        path: "M16.8,11.3c0,0-0.1,0.1-0.1,0.1l-4,3.9c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l3.3,3.3l3.3-3.2c0.4-0.4,1-0.4,1.4-0.1S17.2,10.8,16.8,11.3z"
    },
    chevron_left: {
        id: "chevron-left", viewBox: "0 0 24 24",
        path: "M14.1,15.3c0.4,0.4,0.4,1,0,1.4c-0.2,0.2-0.5,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3l-3.9-4c-0.4-0.4-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L10.9,12L14.1,15.3z"
    },
    chevron_right: {
        id: "chevron-right", viewBox: "0 0 24 24",
        path: "M15.2,12.7l-4,4C11,16.9,10.8,17,10.5,17c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l3.3-3.3L9.9,8.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0l3.9,4C15.6,11.7,15.6,12.3,15.2,12.7z"
    },
    chevron_up: {
        id: "chevron-up", viewBox: "0 0 24 24",
        path: "M16.7,14.2C16.7,14.2,16.7,14.2,16.7,14.2c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3L12,10.9l-3.3,3.2c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l4-3.9c0.4-0.4,1-0.4,1.4,0l4,4C17.1,13.2,17.1,13.8,16.7,14.2z"
    },
    clipboard: {
        id: "clipboard", viewBox: "0 0 24 24",
        path: "M21,7v12c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3v3c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V4C19.7,4,21,5.3,21,7zM8,8h8c0.6,0,1-0.4,1-1V3c0-0.6-0.4-1-1-1H8C7.4,2,7,2.4,7,3v4C7,7.6,7.4,8,8,8z"
    },
    clock: {
        id: "clock", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16,13h-4c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1v3h3c0.6,0,1,0.4,1,1S16.6,13,16,13z"
    },
    close: {
        id: "close", viewBox: "0 0 24 24",
        path: "M17.7,17.7C17.7,17.7,17.7,17.7,17.7,17.7c-0.4,0.4-1,0.4-1.4,0L12,13.4l-4.3,4.3c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4l4.3-4.3L6.3,7.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l4.3,4.3l4.3-4.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L13.4,12l4.3,4.3c0,0,0,0,0,0C18.1,16.7,18.1,17.3,17.7,17.7z"
    },
    close_circle: {
        id: "close-circle", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M14.7,14.7C14.7,14.7,14.7,14.7,14.7,14.7c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L12,13.4l-1.3,1.3c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l1.3-1.3l-1.3-1.3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.3,1.3l1.3-1.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L13.4,12l1.3,1.3C15.1,13.7,15.1,14.3,14.7,14.7z"
    },
    close_square: {
        id: "close-square", viewBox: "0 0 24 24",
        path: "M18,3H6C4.3,3,3,4.3,3,6v12c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V6C21,4.3,19.7,3,18,3z M14.7,14.7C14.7,14.7,14.7,14.7,14.7,14.7c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L12,13.4l-1.3,1.3c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l1.3-1.3l-1.3-1.3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.3,1.3l1.3-1.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L13.4,12l1.3,1.3C15.1,13.7,15.1,14.3,14.7,14.7z"
    },
    cloud_download: {
        id: "cloud-download", viewBox: "0 0 24 24",
        path: "M22,12c0,0.3,0,0.6-0.1,0.9c0,0.1-0.1,0.2-0.1,0.3c0,0.2-0.1,0.4-0.2,0.6l-0.1,0.3c-0.1,0.2-0.2,0.4-0.3,0.6l-0.1,0.2c0,0,0,0.1,0,0.1l0,0c-0.9,1.3-2.4,2-4,2c0-0.5-0.2-0.9-0.6-1.3c-0.4-0.4-0.9-0.6-1.4-0.6c-0.4,0-0.7,0.1-1,0.3V12c0-1.1-0.9-2-2-2s-2,0.9-2,2v3.3C9.7,15.1,9.4,15,9,15c-0.5,0-1,0.2-1.4,0.6C7.2,16,7,16.5,7,17c-1.6,0-3.1-0.7-4-2l0-0.1c0,0,0-0.1,0-0.1l-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.6l-0.1-0.3c-0.1-0.2-0.1-0.4-0.2-0.6c0-0.1-0.1-0.2-0.1-0.3C2,12.6,2,12.3,2,12c0-0.3,0-0.6,0.1-1c0-0.1,0.1-0.2,0.1-0.3c0-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.3,0.2-0.4C2.9,9.2,3,9.1,3,9c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.3L4,8l0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.2l0.4-0.2c0.2-0.1,0.3-0.1,0.5-0.1C5.9,7.1,6,7,6.2,7h0.2c0.6-1.8,2-3.2,3.8-3.8c3.2-1,6.5,0.7,7.5,3.9h0.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1l0.4,0.2c0.2,0.1,0.3,0.1,0.4,0.2L20,8l0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3S21,8.9,21,9s0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.3,0.2,0.4s0.1,0.3,0.2,0.4c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.2,0.1,0.3C22,11.3,22,11.7,22,12z M14.4,16.3c0,0-0.1,0.1-0.1,0.1L13,17.6V12c0-0.6-0.4-1-1-1s-1,0.4-1,1v5.6l-1.3-1.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3,3c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l3-2.9c0.4-0.4,0.5-1,0.1-1.4S14.8,15.9,14.4,16.3z"
    },
    cloud_upload: {
        id: "cloud-upload", viewBox: "0 0 24 24",
        path: "M22,13c0,0.3,0,0.6-0.1,0.9c0,0.1-0.1,0.2-0.1,0.3c0,0.2-0.1,0.4-0.2,0.6l-0.1,0.3c-0.1,0.2-0.2,0.4-0.3,0.6l-0.1,0.2c0,0,0,0.1,0,0.1l0,0c-0.9,1.3-2.4,2-4,2h-3v-1.3c0.3,0.2,0.6,0.3,1,0.3c0.5,0,1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8l-3-3C13,10.2,12.5,10,12,10c-0.5,0-1,0.2-1.4,0.6l-3,2.9c0,0,0,0,0,0c-0.8,0.8-0.8,2,0,2.8c0.4,0.4,0.9,0.6,1.4,0.6c0.4,0,0.7-0.1,1-0.3V18H7c-1.6,0-3.1-0.7-4-2l0-0.1c0,0,0-0.1,0-0.1l-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.6l-0.1-0.3c-0.1-0.2-0.1-0.4-0.2-0.6c0-0.1-0.1-0.2-0.1-0.3C2,13.6,2,13.3,2,13c0-0.3,0-0.6,0.1-1c0-0.1,0.1-0.2,0.1-0.3c0-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.3,0.2-0.4C2.9,10.2,3,10.1,3,10c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.3L4,9l0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.2l0.4-0.2c0.2-0.1,0.3-0.1,0.5-0.1C5.9,8.1,6,8.1,6.2,8l0.2,0c0.6-1.7,2-3.1,3.7-3.7c3.1-1.1,6.5,0.6,7.6,3.7l0.2,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1l0.4,0.2c0.2,0.1,0.3,0.1,0.4,0.2L20,9l0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3S21,9.9,21,10s0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.3,0.2,0.4s0.1,0.3,0.2,0.4c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.2,0.1,0.3C22,12.3,22,12.7,22,13z M15.7,15.7C15.7,15.7,15.7,15.7,15.7,15.7c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0l-3-3c-0.4-0.4-1-0.4-1.4,0l-3,2.9c-0.4,0.4-0.5,1-0.1,1.4s1,0.5,1.4,0.1c0,0,0.1-0.1,0.1-0.1l1.3-1.3V20c0,0.6,0.4,1,1,1s1-0.4,1-1v-5.6l1.3,1.3C14.7,16.1,15.3,16.1,15.7,15.7z"
    },
    code: {
        id: "code", viewBox: "0 0 24 24",
        path: "M8.8,6.6L4.3,12l4.3,5.4c0.1,0.2,0.2,0.4,0.2,0.6c0,0.6-0.5,1-1,1c-0.3,0-0.6-0.1-0.8-0.4l-4.8-6c-0.3-0.4-0.3-0.9,0-1.3l5-6c0,0,0,0,0,0c0.4-0.4,1-0.5,1.4-0.1c0,0,0,0,0,0C9.1,5.6,9.1,6.2,8.8,6.6z M21.8,11.4l-4.8-6c-0.3-0.4-1-0.5-1.4-0.2c0,0,0,0,0,0c-0.4,0.3-0.5,1-0.2,1.4c0,0,0,0,0,0l4.3,5.4l-4.5,5.4c-0.4,0.4-0.3,1.1,0.1,1.4c0,0,0,0,0,0c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4l5-6C22.1,12.3,22.1,11.7,21.8,11.4z"
    },
    code_download: {
        id: "code-download", viewBox: "0 0 24 24",
        path: "M8.6,17.4c0.1,0.2,0.2,0.4,0.2,0.6c0,0.6-0.5,1-1,1c-0.3,0-0.6-0.1-0.8-0.4l-4.8-6c-0.3-0.4-0.3-0.9,0-1.3l5-6c0.4-0.4,1-0.5,1.4-0.1c0.4,0.4,0.5,1,0.1,1.4L4.3,12L8.6,17.4z M21.8,11.4l-4.8-6c-0.3-0.4-1-0.5-1.4-0.2c-0.4,0.3-0.5,1-0.2,1.4l4.3,5.4l-4.5,5.4c-0.4,0.4-0.3,1.1,0.1,1.4c0,0,0,0,0,0c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4l5-6C22.1,12.3,22.1,11.7,21.8,11.4z M15.7,11.4c-0.4-0.4-1-0.4-1.4,0L13,12.6V8c0-0.6-0.4-1-1-1s-1,0.4-1,1v4.6l-1.3-1.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3,3c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l3-2.9C16.1,12.4,16.1,11.8,15.7,11.4z"
    },
    collapse: {
        id: "collapse", viewBox: "0 0 24 24",
        path: "M20,10c0,0.6-0.4,1-1,1h-5c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v2.6l3.3-3.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L16.4,9H19C19.6,9,20,9.4,20,10z M10,13H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h2.6l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0L9,16.4V19c0,0.6,0.4,1,1,1s1-0.4,1-1v-5C11,13.4,10.6,13,10,13z"
    },
    color_pallet: {
        id: "color-pallet", viewBox: "0 0 24 24",
        path: "M19.5,5.1c-2-2-4.8-3.1-7.6-3.1c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10c1.2,0,2.2-0.8,2.5-1.9c0.2-0.8,0-1.7-0.6-2.3c-0.2-0.2-0.2-0.5,0-0.7C14,17,14.1,17,14.2,17h1.7c3.2,0,5.9-2.5,6.2-5.7C22.1,9,21.2,6.7,19.5,5.1z M6.9,14.7C6.8,14.7,6.8,14.7,6.9,14.7c-0.7,0.5-1.6,0.3-2.1-0.4c-0.5-0.7-0.3-1.6,0.4-2.1c0.7-0.5,1.6-0.3,2.1,0.4c0,0,0,0,0,0C7.7,13.3,7.5,14.3,6.9,14.7z M8.3,9.3c-0.4,0.7-1.3,1-2,0.6c-0.7-0.4-1-1.3-0.6-2c0.4-0.7,1.3-1,2-0.6c0,0,0,0,0.1,0C8.4,7.7,8.7,8.5,8.3,9.3z M11,7c-0.8,0-1.5-0.7-1.5-1.5S10.2,4,11,4s1.5,0.7,1.5,1.5S11.8,7,11,7z M16.8,7.8C16,8.2,15.1,8,14.7,7.3c-0.4-0.7-0.2-1.6,0.5-2.1c0.7-0.4,1.6-0.2,2.1,0.5c0,0,0,0,0,0.1C17.7,6.5,17.4,7.4,16.8,7.8z"
    },
    color_picker: {
        id: "color-picker", viewBox: "0 0 24 24",
        path: "M19.4,7.3l-2.7-2.7c-0.7-0.7-1.9-0.8-2.7-0.1l-2,2l-1.3-1.2c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L10.5,8L5,13.5c-0.3,0.3-0.5,0.8-0.6,1.2L4,18.9c0,0.3,0.1,0.6,0.3,0.8C4.5,19.9,4.7,20,5,20h0.1l4.2-0.4c0.5,0,0.9-0.2,1.2-0.6l5.6-5.6l1.2,1.2c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0L17.5,12l2-2C20.2,9.3,20.2,8.1,19.4,7.3z M11.9,14.8l-5.5,0.1L12,9.4l2.7,2.7L11.9,14.8z"
    },
    compass: {
        id: "compass", viewBox: "0 0 24 24",
        path: "M11.5,11.5l1.7-0.7l-0.7,1.7l-1.7,0.7L11.5,11.5z M22,12c0,5.5-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2S22,6.5,22,12zM15.7,8.4C15.4,8.1,15,8,14.6,8.2l-4.2,1.7c-0.3,0.1-0.4,0.3-0.5,0.5l-1.8,4.3c-0.1,0.3-0.1,0.7,0.2,1h0C8.5,15.9,8.7,16,9,16c0.1,0,0.3,0,0.4-0.1l4.2-1.7c0.3-0.1,0.4-0.3,0.5-0.5l1.8-4.3C16,9.1,15.9,8.7,15.7,8.4z"
    },
    copy: {
        id: "copy", viewBox: "0 0 24 24",
        path: "M18,9h-3V5.7C15,4.2,13.8,3,12.3,3H5.7C4.2,3,3,4.2,3,5.7v6.7C3,13.8,4.2,15,5.7,15H9v3c0,1.7,1.3,3,3,3h6c1.7,0,3-1.3,3-3v-6C21,10.3,19.7,9,18,9z M13,9h-1c-1.7,0-3,1.3-3,3v1H5.7C5.3,13,5,12.7,5,12.3V5.7C5,5.3,5.3,5,5.7,5h6.7C12.7,5,13,5.3,13,5.7V9z"
    },
    corner_down_left: {
        id: "corner-down-left", viewBox: "0 0 24 24",
        path: "M20.1,11.1c0,1.7-1.3,3-3,3H8.1l2.7,3.4c0,0,0,0,0,0c0.3,0.4,0.3,1.1-0.2,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.8-0.4l-4-5c-0.3-0.4-0.3-0.9,0-1.3l4-5c0.3-0.2,0.6-0.2,0.9-0.1c0.5,0.2,0.8,0.8,0.6,1.3L8.1,12H17c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7V6c0-0.6,0.4-1,1-1s1,0.4,1,1L20.1,11.1z"
    },
    corner_down_right: {
        id: "corner-down-right", viewBox: "0 0 24 24",
        path: "M19.8,13.6l-4,5C15.6,18.9,15.3,19,15,19c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-0.5-1-0.2-1.4l2.7-3.4H8c-1.7,0-3-1.3-3-3V6c0-0.6,0.4-1,1-1s1,0.4,1,1v5c0,0.6,0.4,1,1,1h8.9l-2.7-3.4C14,8.2,14,7.7,14.4,7.3c0.4-0.4,1-0.4,1.4,0l4,5C20.1,12.7,20.1,13.3,19.8,13.6z"
    },
    corner_left_down: {
        id: "corner-left-down", viewBox: "0 0 24 24",
        path: "M19,6c0,0.6-0.4,1-1,1h-5c-0.6,0-1,0.4-1,1v8.9l3.4-2.7c0,0,0.1-0.1,0.1-0.1c0.5-0.3,1.1-0.2,1.4,0.3s0.2,1.1-0.3,1.4l-5,4c-0.4,0.3-0.9,0.3-1.2,0l-5-4C5,15.4,5,14.9,5.2,14.5c0.3-0.5,0.9-0.6,1.4-0.3l3.4,2.7V8c0-1.7,1.3-3,3-3h5C18.6,5,19,5.4,19,6z"
    },
    corner_left_up: {
        id: "corner-left-up", viewBox: "0 0 24 24",
        path: "M19,18c0,0.6-0.4,1-1,1h-5c-1.7,0-3-1.3-3-3V7.1L6.6,9.8C6.2,10,5.7,10,5.3,9.6C5,9.2,5,8.6,5.4,8.2l5-4c0.4-0.3,0.9-0.3,1.2,0l5,4c0.4,0.3,0.5,1,0.2,1.4C16.6,9.9,16.3,10,16,10c-0.2,0-0.4-0.1-0.6-0.2L12,7.1V16c0,0.6,0.4,1,1,1h5C18.6,17,19,17.4,19,18z"
    },
    corner_right_down: {
        id: "corner-right-down", viewBox: "0 0 24 24",
        path: "M18.8,9.6C18.6,9.9,18.3,10,18,10c-0.2,0-0.4-0.1-0.6-0.2L14,7.1V16c0,1.7-1.3,3-3,3H6c-0.6,0-1-0.4-1-1s0.4-1,1-1h5c0.6,0,1-0.4,1-1V7.1L8.6,9.8C8.2,10,7.7,10,7.3,9.6C7,9.2,7,8.6,7.4,8.2l5-4c0.4-0.3,0.9-0.3,1.2,0l5,4C19,8.6,19.1,9.2,18.8,9.6z"
    },
    corner_right_up: {
        id: "corner-right-up", viewBox: "0 0 24 24",
        path: "M18.8,9.6C18.6,9.9,18.3,10,18,10c-0.2,0-0.4-0.1-0.6-0.2L14,7.1V16c0,1.7-1.3,3-3,3H6c-0.6,0-1-0.4-1-1s0.4-1,1-1h5c0.6,0,1-0.4,1-1V7.1L8.6,9.8C8.2,10,7.7,10,7.3,9.6C7,9.2,7,8.6,7.4,8.2l5-4c0.4-0.3,0.9-0.3,1.2,0l5,4C19,8.6,19.1,9.2,18.8,9.6z"
    },
    corner_up_left: {
        id: "corner-up-left", viewBox: "0 0 24 24",
        path: "M19,13v5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-5c0-0.6-0.4-1-1-1H7.1l2.7,3.4c0.3,0.4,0.3,1.1-0.2,1.4C9.4,16.9,9.2,17,9,17c-0.3,0-0.6-0.1-0.8-0.4l-4-5c-0.3-0.4-0.3-0.9,0-1.2l4-5c0,0,0.1-0.1,0.1-0.1c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L7.1,10H16C17.7,10,19,11.3,19,13z"
    },
    corner_up_right: {
        id: "corner-up-right", viewBox: "0 0 24 24",
        path: "M19.8,11.6l-4,5C15.6,16.9,15.3,17,15,17c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-0.5-1-0.2-1.4l2.7-3.4H8c-0.6,0-1,0.4-1,1v5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-5c0-1.7,1.3-3,3-3h8.9l-2.7-3.4C14,6.2,14,5.7,14.4,5.3c0.4-0.4,1-0.4,1.4,0l4,5C20.1,10.7,20.1,11.3,19.8,11.6z"
    },
    credit_card: {
        id: "credit-card", viewBox: "0 0 24 24",
        path: "M19,5H5C3.3,5,2,6.3,2,8v8c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3V8C22,6.3,20.7,5,19,5z M11,15H7c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S11.6,15,11,15z M17,15h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c0.6,0,1,0.4,1,1S17.6,15,17,15z M20,9H4V8c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1V9z"
    },
    crop: {
        id: "crop", viewBox: "0 0 24 24",
        path: "M21,16h-3V8.6C18,7.1,16.9,6,15.4,6H8V3c0-0.6-0.4-1-1-1S6,2.4,6,3v3H3C2.4,6,2,6.4,2,7s0.4,1,1,1h3v7.4C6,16.9,7.1,18,8.6,18H16v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3h3c0.6,0,1-0.4,1-1S21.6,16,21,16z M16,16H8.6C8.3,16,8,15.7,8,15.4V8h7.4C15.7,8,16,8.3,16,8.6V16z"
    },
    cube: {
        id: "cube", viewBox: "0 0 24 24",
        path: "M3,8.4l8.3,3.5V21h-0.1L4,17.6c-0.6-0.3-1-0.9-1-1.5V8.4z M20,6.5l-7.2-3.4c-0.5-0.2-1.1-0.2-1.6,0L4,6.5C3.8,6.6,3.6,6.8,3.5,6.9l8.5,3.6l8.5-3.6C20.4,6.8,20.2,6.6,20,6.5z M12.8,11.8V21h0l7.2-3.4c0.6-0.3,1-0.9,1-1.5V8.4L12.8,11.8z"
    },

    diagonal_arrow_left_down: {
        id: "diagonal-arrow-left-down", viewBox: "0 0 24 24",
        path: "M17.7,7.7L9.4,16H15c0.6,0,1,0.4,1,1s-0.4,1-1,1H7c-0.6,0-1-0.4-1-1V9c0-0.6,0.4-1,1-1s1,0.4,1,1v5.6l8.3-8.3c0,0,0,0,0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0C18.1,6.7,18.1,7.3,17.7,7.7z"
    },
    diagonal_arrow_left_up: {
        id: "diagonal-arrow-left-up", viewBox: "0 0 24 24",
        path: "M17.7,17.7C17.7,17.7,17.7,17.7,17.7,17.7c-0.4,0.4-1,0.4-1.4,0L8,9.5V15c0,0.5-0.4,1-1,1c-0.5,0-1-0.5-1-1V7c0-0.6,0.4-1,1-1h8c0.6,0,1,0.4,1,1s-0.4,1-1,1H9.4l8.3,8.3c0,0,0,0,0,0C18.1,16.7,18.1,17.3,17.7,17.7z M7,16C7,16,7,16,7,16C7,16,7,16,7,16L7,16z"
    },
    diagonal_arrow_right_down: {
        id: "diagonal-arrow-right-down", viewBox: "0 0 24 24",
        path: "M18,9v8c0,0.6-0.4,1-1,1H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h5.6L6.3,7.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l8.3,8.3V9c0-0.6,0.4-1,1-1S18,8.4,18,9z"
    },
    diagonal_arrow_right_up: {
        id: "diagonal-arrow-right-up", viewBox: "0 0 24 24",
        path: "M18,7v8c0,0.6-0.4,1-1,1s-1-0.4-1-1V9.4l-8.3,8.3c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4L14.6,8H9C8.4,8,8,7.6,8,7s0.4-1,1-1l8,0C17.6,6,18,6.5,18,7z"
    },
    done_all: {
        id: "done-all", viewBox: "0 0 24 24",
        path: "M16.8,7.6L9,17.6C8.8,17.9,8.5,18,8.2,18c-0.3,0-0.6-0.1-0.8-0.4l-4.2-5.2c0,0,0,0,0,0C2.8,12,2.9,11.3,3.4,11c0.4-0.3,1.1-0.2,1.4,0.2l3.4,4.2l7-9c0,0,0,0,0,0c0.3-0.4,1-0.5,1.4-0.2c0,0,0,0,0,0C17.1,6.6,17.2,7.2,16.8,7.6z M21.6,6.2C21.6,6.2,21.6,6.2,21.6,6.2c-0.4-0.3-1.1-0.3-1.4,0.2c0,0,0,0,0,0l-7,9l-0.6-0.8l-1.3,1.6l1.1,1.4c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4l7.8-10C22.2,7.2,22.1,6.5,21.6,6.2z M8.2,12.4l0.5,0.7l1.3-1.6l-0.2-0.2c-0.3-0.4-0.9-0.5-1.4-0.2c0,0,0,0,0,0C7.9,11.3,7.9,12,8.2,12.4C8.2,12.4,8.2,12.4,8.2,12.4z"
    },
    download: {
        id: "download", viewBox: "0 0 24 24",
        path: "M20,17v2c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1s1,0.4,1,1v1h12v-1c0-0.6,0.4-1,1-1S20,16.4,20,17zM11.4,14.8c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l4-3c0.4-0.3,0.5-1,0.2-1.4s-1-0.5-1.4-0.2L13,12c0,0,0,0,0,0V4c0-0.6-0.4-1-1-1s-1,0.4-1,1v8c0,0,0,0,0,0.1l-2.4-1.7c0,0,0,0,0,0c-0.5-0.3-1.1-0.2-1.4,0.2C6.9,11.1,7,11.7,7.4,12L11.4,14.8z"
    },
    droplet: {
        id: "droplet", viewBox: "0 0 24 24",
        path: "M17.3,18.9c-1.4,1.4-3.3,2.2-5.3,2.2c-2,0-3.9-0.8-5.3-2.3C3.8,15.8,3.9,11,6.8,8l4.6-4.6c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3l4.6,4.7C20.3,11.2,20.3,15.9,17.3,18.9z"
    },
    droplet_off: {
        id: "droplet-off", viewBox: "0 0 24 24",
        path: "M19,16.1L8.7,5.9l2.6-2.6C11.5,3.1,11.8,3,12.1,3c0.3,0,0.5,0.1,0.7,0.3L17.3,8C19.4,10.2,20.1,13.3,19,16.1z M6.6,18.7C8,20.2,9.9,21,11.9,21c1.7,0,3.3-0.6,4.6-1.6L6,8.8C3.8,11.8,4.1,16,6.6,18.7z M20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3l-16-16c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l16,16c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3z"
    },

    edit: {
        id: "edit", viewBox: "0 0 24 24",
        path: "M19.4,7.3l-2.7-2.7c-0.7-0.7-1.9-0.7-2.7-0.1l-9,9c-0.3,0.3-0.5,0.8-0.6,1.2L4,18.9c0,0.3,0.1,0.6,0.3,0.8C4.5,19.9,4.7,20,5,20h0.1l4.2-0.4c0.5,0,0.9-0.2,1.2-0.6l9-9C20.2,9.3,20.2,8.1,19.4,7.3z M16,10.7L13.3,8l2-2L18,8.7L16,10.7z"
    },
    edit_2: {
        id: "edit-2", viewBox: "0 0 24 24",
        path: "M20,21c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1s0.4-1,1-1h14C19.6,20,20,20.4,20,21z M4.3,17.7C4.1,17.5,4,17.2,4,16.9l0.4-4.2c0-0.5,0.2-0.9,0.6-1.2l9-9c0.8-0.7,1.9-0.6,2.7,0.1l2.7,2.7c0.8,0.7,0.8,1.9,0.1,2.7l-9,9c-0.3,0.3-0.8,0.5-1.2,0.6L5.1,18H5C4.7,18,4.5,17.9,4.3,17.7z M13.3,6L16,8.7l2-2L15.3,4L13.3,6z"
    },
    email: {
        id: "email", viewBox: "0 0 24 24",
        path: "M19,4H5C3.3,4,2,5.3,2,7v10c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3V7C22,5.3,20.7,4,19,4z M12.5,10.5c-0.3,0.2-0.7,0.2-1,0L5,6h14L12.5,10.5z"
    },
    expand: {
        id: "expand", viewBox: "0 0 24 24",
        path: "M20,5v5c0,0.6-0.4,1-1,1s-1-0.4-1-1V7.4l-3.3,3.3c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4L16.6,6H14c-0.6,0-1-0.4-1-1s0.4-1,1-1h5C19.6,4,20,4.4,20,5z M10.7,13.3C10.7,13.3,10.7,13.3,10.7,13.3c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0L6,16.6V14c0-0.6-0.4-1-1-1s-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1s-0.4-1-1-1H7.4l3.3-3.3C11.1,14.3,11.1,13.7,10.7,13.3z"
    },
    external_link: {
        id: "external-link", viewBox: "0 0 24 24",
        path: "M21,12v6c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3h6c0.6,0,1,0.4,1,1s-0.4,1-1,1H6C5.4,5,5,5.4,5,6v12c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1v-6c0-0.6,0.4-1,1-1S21,11.4,21,12z M20,3h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.6l-6.3,6.3c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0L19,6.4V8c0,0.6,0.4,1,1,1s1-0.4,1-1V4C21,3.4,20.6,3,20,3z"
    },
    eye: {
        id: "eye", viewBox: "0 0 24 24",
        path: "M13.5,12c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5S13.5,11.2,13.5,12z M21.9,12.5C21,14,17.8,18.9,12.3,19H12c-5.9,0-9.3-5.4-9.9-6.5c-0.2-0.3-0.2-0.7,0-1C3,10,6.2,5.1,11.7,5c6-0.2,9.5,5.4,10.1,6.5C22,11.8,22,12.2,21.9,12.5z M15.5,12c0-1.9-1.6-3.5-3.5-3.5S8.5,10.1,8.5,12s1.6,3.5,3.5,3.5S15.5,13.9,15.5,12z"
    },
    eye_off: {
        id: "eye-off", viewBox: "0 0 24 24",
        path: "M15.3,18.1l0.2,0.2c-1,0.4-2.1,0.6-3.2,0.7H12c-5.9,0-9.3-5.4-9.9-6.5c-0.2-0.3-0.2-0.7,0-1C2.9,10.2,3.9,8.9,5,7.9l1.6,1.5l2,2c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0,0,0.1c0,1.9,1.6,3.5,3.5,3.4c0.2,0,0.4,0,0.6-0.1l1.3,1.3l0.1,0.1L15.3,18.1z M21.9,11.5c-0.6-1.1-4.2-6.7-10.1-6.5c-1.1,0-2.2,0.3-3.2,0.7l0.1,0.1l2.8,2.8c0.2,0,0.4-0.1,0.6-0.1c1.9,0,3.5,1.6,3.5,3.5c0,0.2,0,0.4-0.1,0.6l2.7,2.7l0.8,0.8c1.1-1.1,2.1-2.3,2.9-3.6C22,12.2,22,11.8,21.9,11.5z M20.7,19.3L19.4,18l-2-2l-3.9-3.9c0,0,0-0.1,0-0.1c0-0.8-0.7-1.5-1.5-1.5c0,0-0.1,0-0.1,0l-4-4L6.4,5L4.7,3.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L5.5,7l1.8,1.7l3.2,3.2c0,0,0,0.1,0,0.1c0,0.8,0.7,1.5,1.5,1.5c0,0,0.1,0,0.1,0l2.5,2.5l0.1,0.1l1.3,1.3l0.6,0.6l2.7,2.7c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3z"
    },
    eye_off_2: {
        id: "eye-off-2", viewBox: "0 0 24 24",
        path: "M20.1,15.7c0.3,0.4,0.3,0.9,0,1.3c-0.4,0.4-1,0.5-1.4,0.1l-2.6-2.6c-1,0.5-2,0.8-3.1,0.9V19c0,0.6-0.4,1-1,1s-1-0.4-1-1v-3.6c-1.1-0.1-2.1-0.4-3.1-0.9l-2.6,2.6c-0.4,0.3-0.9,0.3-1.3,0c-0.4-0.4-0.5-1-0.1-1.4l2.3-2.3C4.4,11.9,3.3,9.9,3,7.6C2.9,7.1,3.3,6.6,3.9,6.5S4.9,6.8,5,7.4c0.4,3.1,2.9,5.6,6,6c3.9,0.5,7.4-2.1,8-6c0.1-0.6,0.6-0.9,1.1-0.9s0.9,0.6,0.9,1.1c-0.3,2.3-1.4,4.3-3.2,5.8L20.1,15.7z"
    },

   facebook: {
        id: "facebook", viewBox: "0 0 24 24",
        path: "M17,3.5v2.6c0,0.3-0.2,0.5-0.5,0.5H14c-0.5,0-0.9,0.4-1,0.9v2.7h3.3c0,0,0.1,0,0.1,0c0.3,0.1,0.4,0.3,0.4,0.6l-0.7,2.6c-0.1,0.2-0.3,0.4-0.5,0.4H13v6.7c0,0.3-0.2,0.5-0.5,0.5h-3C9.2,21,9,20.8,9,20.5v-6.7H6.5c-0.3,0-0.5-0.2-0.5-0.5v-2.6c0-0.3,0.2-0.5,0.5-0.5H9V7.5c0.1-2.6,2.4-4.6,5-4.5h2.5C16.8,3,17,3.2,17,3.5z"
    },
    file: {
        id: "file", viewBox: "0 0 24 24",
        path: "M19.7,7.3l-4.4-5C15.1,2.1,14.8,2,14.6,2h-8C5.2,2,4,3.1,4,4.5c0,0,0,0,0,0v15C4,20.9,5.2,22,6.6,22c0,0,0,0,0,0h10.9c1.4,0,2.5-1.1,2.6-2.5c0,0,0,0,0,0V8C20,7.8,19.9,7.5,19.7,7.3z M14.7,8C14.3,8,14,7.6,14,7.2c0,0,0,0,0,0V4l3.7,4H14.7z"
    },
    file_add: {
        id: "file-add", viewBox: "0 0 24 24",
        path: "M19.7,7.3l-4.4-5C15.1,2.1,14.8,2,14.6,2h-8C5.2,2,4,3.1,4,4.5c0,0,0,0,0,0v15C4,20.9,5.2,22,6.6,22c0,0,0,0,0,0h10.9c1.4,0,2.5-1.1,2.6-2.5c0,0,0,0,0,0V8C20,7.8,19.9,7.5,19.7,7.3z M14,15h-1v1c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1h-1c-0.6,0-1-0.4-1-1s0.4-1,1-1h1v-1c0-0.6,0.4-1,1-1s1,0.4,1,1v1h1c0.6,0,1,0.4,1,1S14.6,15,14,15z M14.7,8C14.3,8,14,7.6,14,7.2V4l3.7,4H14.7z"
    },
    file_remove: {
        id: "file-remove", viewBox: "0 0 24 24",
        path: "M19.7,7.3l-4.4-5C15.1,2.1,14.8,2,14.6,2h-8C5.2,2,4,3.1,4,4.5c0,0,0,0,0,0v15C4,20.9,5.2,22,6.6,22c0,0,0,0,0,0h10.9c1.4,0,2.5-1.1,2.6-2.5c0,0,0,0,0,0V8C20,7.8,19.9,7.5,19.7,7.3z M14,15h-4c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S14.6,15,14,15z M14.7,8C14.3,8,14,7.6,14,7.2V4l3.7,4H14.7z"
    },
    file_text: {
        id: "file-text", viewBox: "0 0 24 24",
        path: "M19.7,7.3l-4.4-5C15.1,2.1,14.8,2,14.6,2h-8C5.2,2,4,3.1,4,4.5c0,0,0,0,0,0v15C4,20.9,5.2,22,6.6,22c0,0,0,0,0,0h10.9c1.4,0,2.5-1.1,2.6-2.5c0,0,0,0,0,0V8C20,7.8,19.9,7.5,19.7,7.3z M9,12h3c0.6,0,1,0.4,1,1s-0.4,1-1,1H9c-0.6,0-1-0.4-1-1S8.4,12,9,12z M15,18H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S15.6,18,15,18z M14.7,8C14.3,8,14,7.6,14,7.2V4l3.7,4H14.7z"
    },
    film: {
        id: "film", viewBox: "0 0 24 24",
        path: "M18.3,3H5.7C4.2,3,3,4.2,3,5.7v12.5C3,19.8,4.2,21,5.7,21h12.5c1.5,0,2.7-1.2,2.7-2.7V5.7C21,4.2,19.8,3,18.3,3z M7,19H5.7C5.3,19,5,18.7,5,18.3V17h2V19z M7,15H5v-2h2V15z M7,11H5V9h2V11z M7,7H5V5.7C5,5.3,5.3,5,5.7,5H7V7z M19,18.3c0,0.4-0.3,0.7-0.7,0.7H17v-2h2V18.3z M19,15h-2v-2h2V15z M19,11h-2V9h2V11z M19,7h-2V5h1.3C18.7,5,19,5.3,19,5.7V7z"
    },
    filter: {
        id: "filter", viewBox: "0 0 24 24",
        path: "M19.9,5.5l-5,9.2V21c0,0.4-0.2,0.7-0.5,0.9C14.2,22,14,22,13.9,22c-0.2,0-0.4-0.1-0.6-0.2l-4-3c-0.2-0.2-0.4-0.5-0.4-0.8v-3.3L4.1,5.5C3.9,5,4,4.4,4.5,4.1C4.7,4,4.8,4,5,4h14c0.4,0,0.7,0.2,0.9,0.5C20,4.8,20,5.2,19.9,5.5z"
    },
    flag: {
        id: "flag", viewBox: "0 0 24 24",
        path: "M20,6.1v8.6c0,0.7-0.4,1.3-1,1.5c-1.1,0.5-2.3,0.7-3.5,0.7c-1.3-0.1-2.7-0.4-3.9-0.9c-1-0.5-2-0.7-3.1-0.8c-0.9,0-1.7,0.1-2.5,0.4V20c0,0.6-0.4,1-1,1s-1-0.4-1-1V4.9c0-0.3,0.1-0.5,0.3-0.7C4.5,4,5.6,3,8.5,3c1.3,0.1,2.7,0.4,3.9,0.9c1,0.5,2,0.7,3.1,0.8c0.7,0,1.5-0.1,2.2-0.3c0.5-0.2,1.1-0.1,1.6,0.3C19.7,5,20,5.5,20,6.1z"
    },
    flash: {
        id: "flash", viewBox: "0 0 24 24",
        path: "M20,10.2c0,0.2-0.1,0.4-0.2,0.6l-7.9,11.8c-0.2,0.3-0.5,0.4-0.8,0.4c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.7-0.6-0.7-1l0.8-7.1H5c-0.6,0-1-0.4-1-1c0-0.2,0.1-0.4,0.2-0.6l7.9-11.8c0.3-0.4,0.7-0.5,1.2-0.4c0.4,0.2,0.7,0.6,0.7,1l-0.8,7.1H19C19.6,9.2,20,9.6,20,10.2z"
    },
    flash_off: {
        id: "flash-off", viewBox: "0 0 24 24",
        path: "M17.3,14.5L8.9,6.1l3.1-4.6c0.3-0.4,0.7-0.5,1.2-0.4c0.4,0.2,0.7,0.6,0.7,1l-0.8,7.1H19c0.2,0,0.4,0.1,0.6,0.2c0.5,0.3,0.6,0.9,0.3,1.4L17.3,14.5z M4.2,13.2c-0.3,0.5-0.2,1.1,0.3,1.4c0.2,0.1,0.4,0.2,0.6,0.2h5.9l-0.8,7.1c-0.1,0.5,0.2,0.9,0.7,1c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.8-0.4l3.1-4.7L6.7,9.5L4.2,13.2z M20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3l-16-16c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l16,16c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3z"
    },
    flip: {
        id: "flip", viewBox: "0 0 24 24",
        path: "M12,3.5c0,0.6-0.4,1-1,1H8.5c0,0,0,0,0,0C7.7,4.5,7,5.2,7,6.1v12l1.3-1.3c0,0,0,0,0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0c0.4,0.4,0.4,1,0,1.4l-3,3c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0l-3-3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0L5,18.1v-12c0,0,0,0,0,0c0-2,1.6-3.6,3.5-3.6H11C11.6,2.5,12,2.9,12,3.5z M21.7,5.8C21.7,5.8,21.7,5.8,21.7,5.8l-3-3c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-3,3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0L17,5.9v12c0,0.9-0.7,1.6-1.5,1.6c0,0,0,0,0,0H13c-0.6,0-1,0.4-1,1s0.4,1,1,1h2.5c2,0,3.5-1.6,3.5-3.6c0,0,0,0,0,0v-12l1.3,1.3c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C22.1,6.8,22.1,6.2,21.7,5.8z"
    },
    flip_2: {
        id: "flip_2", viewBox: "0 0 24 24",
        path: "M21.2,18.7l-3,3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l1.3-1.3h-12c0,0,0,0,0,0c-2,0-3.6-1.6-3.6-3.5V13c0-0.6,0.4-1,1-1s1,0.4,1,1v2.5c0,0,0,0,0,0c0,0.9,0.7,1.5,1.6,1.5h12l-1.3-1.3c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0c0.4-0.4,1-0.4,1.4,0l3,3c0,0,0,0,0,0C21.6,17.7,21.6,18.3,21.2,18.7z M2.8,6.7l3,3c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L5.9,7h12c0.9,0,1.6,0.7,1.6,1.5c0,0,0,0,0,0V11c0,0.6,0.4,1,1,1s1-0.4,1-1V8.5c0-2-1.6-3.5-3.6-3.5c0,0,0,0,0,0h-12l1.3-1.3c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-3,3C2.4,5.7,2.4,6.3,2.8,6.7C2.8,6.7,2.8,6.7,2.8,6.7z"
    },
    folder: {
        id: "folder", viewBox: "0 0 24 24",
        path: "M22,9.5v8.6c0,1.4-1.1,2.4-2.5,2.4h-15c-1.4,0-2.5-1.1-2.5-2.4V5.9c0-1.4,1.1-2.4,2.5-2.4h4.6c0.3,0,0.6,0.1,0.8,0.4L12.5,7h7C20.8,7,22,8.1,22,9.5C22,9.5,22,9.5,22,9.5z"
    },
    folder_add: {
        id: "folder-add", viewBox: "0 0 24 24",
        path: "M19.5,7h-7L9.9,3.9C9.7,3.6,9.4,3.5,9.1,3.5H4.5C3.1,3.5,2,4.6,2,5.9v12.1c0,1.4,1.1,2.4,2.5,2.4h15c1.4,0,2.5-1.1,2.5-2.4V9.5C22,8.1,20.9,7,19.5,7z M14,15h-1v1c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1h-1c-0.6,0-1-0.4-1-1s0.4-1,1-1h1v-1c0-0.6,0.4-1,1-1s1,0.4,1,1v1h1c0.6,0,1,0.4,1,1S14.6,15,14,15z"
    },
    folder_remove: {
        id: "folder-remove", viewBox: "0 0 24 24",
        path: "M19.5,7h-7L9.9,3.9C9.7,3.6,9.4,3.5,9.1,3.5H4.5C3.1,3.5,2,4.6,2,5.9v12.1c0,1.4,1.1,2.4,2.5,2.4h15c1.4,0,2.5-1.1,2.5-2.4V9.5C22,8.1,20.9,7,19.5,7z M14,15h-4c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S14.6,15,14,15z"
    },

    gift: {
        id: "gift", viewBox: "0 0 24 24",
        path: "M4.6,15.3h6.5V21H5.6c-0.5,0-0.9-0.4-0.9-0.9V15.3z M12.8,21h5.6c0.5,0,0.9-0.4,0.9-0.9v-4.8h-6.5V21z M21,8.4v3.9c0,0.7-0.4,1.3-0.9,1.3h-7.3V7.1h-1.6v6.5H3.9C3.4,13.6,3,13,3,12.3V8.4c0-0.7,0.4-1.3,0.9-1.3h1.8C5.6,6.7,5.5,6.3,5.5,5.9C5.5,4.3,6.7,3,8.3,3c1.6,0.1,3.1,1.1,3.7,2.6c0.6-1.5,2.1-2.5,3.7-2.6c1.6,0,2.9,1.3,2.9,2.9c0,0.4-0.1,0.8-0.3,1.2h1.8C20.6,7.1,21,7.7,21,8.4z M10.9,7.1c-0.4-1-1.2-2.5-2.6-2.5c-0.1,0-0.1,0-0.2,0C7.4,4.7,6.9,5.3,7,6C7,6.7,7.6,7.2,8.3,7.1H10.9zM17,5.8c-0.1-0.7-0.7-1.2-1.3-1.1c-1.4,0-2.2,1.5-2.6,2.5h2.6c0.1,0,0.1,0,0.2,0C16.6,7,17.1,6.4,17,5.8z"
    },
    github: {
        id: "github", viewBox: "0 0 24 24",
        path: "M23,11.8c0,4.7-3.1,8.8-7.5,10.2c-0.5,0.1-0.8-0.2-0.8-0.5v-2.9c0.1-0.7-0.2-1.5-0.8-2c2.4-0.3,5-1.2,5-5.3c0-1.1-0.4-2.1-1.2-2.9c0.3-0.9,0.3-1.9-0.1-2.8c0,0-0.9-0.3-3,1.1c-1.8-0.5-3.7-0.5-5.5,0c-2.1-1.4-3-1.1-3-1.1C5.8,6.4,5.8,7.4,6.1,8.3C5.4,9.1,5,10.1,5,11.2c0,4.1,2.6,5.1,5,5.3c-0.4,0.4-0.6,0.9-0.7,1.4c-1.1,0.6-2.6,0.2-3.2-0.9c-0.4-0.6-1-1-1.7-1.1c0,0-1.1,0-0.1,0.7c0.6,0.4,1,0.9,1.2,1.6c0,0,0.7,2.1,3.7,1.4v1.8c0,0.3-0.2,0.6-0.8,0.5C4.1,20.6,1,16.5,1,11.8C1.1,5.8,6,0.9,12,1C18,0.9,22.9,5.8,23,11.8z"
    },
    globe: {
        id: "globe", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M15.1,10.7C15,11.1,15,11.5,15,12c0,0.5-0.1,1-0.3,1.5l-0.1,0.2c-0.6,1.1-1.4,2.3-0.4,4c0.1,0.2,0.3,0.4,0.4,0.6c0.3,0.3,0.4,0.7,0.5,1.1c-1,0.4-2,0.7-3.1,0.7c-2.9,0-5.6-1.6-7-4.2c0.7,0.2,1.3,0.3,2,0.3c1.1,0,2.2-0.3,3.1-1c1.7-1.3,1.7-3.1,1.7-4.4c-0.1-0.8,0.1-1.6,0.4-2.3c0.3-0.4,0.7-0.7,1.1-0.9C13.7,7.2,14,7,14.3,6.8c0.6-0.5,1.1-1.1,1.4-1.9c1.4,0.7,2.6,1.8,3.3,3.2C17.6,8.3,15.5,8.8,15.1,10.7z"
    },
    globe_2: {
        id: "globe-2", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,5.2c-0.2,0.2-0.5,0.3-0.7,0.5c-0.8,0.4-1.4,1-1.8,1.7c-0.5,1-0.7,2.2-0.6,3.3c0,1.4,0,2.2-1,2.9c-1.4,1.1-3.5,0.5-4.8-0.1C4,13,4,12.5,4,12c0-4.4,3.6-8,8-8c0.6,0,1.2,0.1,1.8,0.2C13.6,4.6,13.3,4.9,13,5.2z M16.9,18.4L16.9,18.4c-0.2-0.4-0.4-0.8-0.6-1.2c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4-0.7-0.3-1,0.4-2l0.1-0.2c0.4-0.7,0.6-1.6,0.6-2.4c0-0.3,0-0.7,0.1-1c0.2-0.7,1.7-0.9,2.7-1C20.6,13.2,19.4,16.4,16.9,18.4z"
    },
    globe_3: {
        id: "globe-3", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M19.9,11H17c-0.2-2.4-1-4.6-2.3-6.5C17.5,5.5,19.6,8,19.9,11z M15,11H9.1c0.2-2.5,1.3-4.8,2.9-6.6C13.7,6.2,14.8,8.5,15,11z M9.4,4.4C8.1,6.4,7.3,8.6,7.1,11h-3C4.5,8,6.5,5.4,9.4,4.4z M4.1,13h3c0.2,2.4,1,4.6,2.3,6.6C6.5,18.6,4.5,16,4.1,13z M9.1,13H15c-0.3,2.5-1.3,4.8-3,6.6C10.3,17.8,9.3,15.5,9.1,13z M14.6,19.5c1.4-1.9,2.2-4.2,2.4-6.5h3C19.6,16,17.5,18.6,14.6,19.5z"
    },
    google: {
        id: "google", viewBox: "0 0 24 24",
        path: "M22,10.5v2c-0.3,5.5-4.9,9.7-10.4,9.5c-5.2-0.3-9.4-4.5-9.6-9.7c0-1.5,0.3-3,0.9-4.4c2.3-5,8.2-7.3,13.2-5c0,0,0,0,0,0c0.3,0.1,0.4,0.4,0.2,0.7l-1.4,2.6c-0.1,0.2-0.4,0.3-0.6,0.2C13.5,6.2,12.8,6,12,6c-0.2,0-0.3,0-0.5,0C8.2,6.3,5.7,9.2,6,12.5c0,0.1,0,0.2,0,0.2c0.5,3.4,3.6,5.7,7,5.2c2.1-0.3,3.9-1.8,4.5-3.9h-5c-0.3,0-0.5-0.2-0.5-0.5v-3c0-0.3,0.2-0.5,0.5-0.5h9C21.8,10,22,10.2,22,10.5z"
    },
    grid: {
        id: "grid", viewBox: "0 0 24 24",
        path: "M11,5v4c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h4C10.1,3,11,3.9,11,5z M19,3h-4c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M9,13H5c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-4C11,13.9,10.1,13,9,13z M19,13h-4c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-4C21,13.9,20.1,13,19,13z"
    },
    hard_drive: {
        id: "hard-drive", viewBox: "0 0 24 24",
        path: "M20.8,11.3l-3.3-6.7c-0.5-1-1.6-1.7-2.7-1.7H9.2C8.1,3,7.1,3.6,6.5,4.7l-3.3,6.7C3.1,11.6,3,11.9,3,12.2V18c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3v-5.8C21,11.9,20.9,11.6,20.8,11.3z M8,17c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S8.6,17,8,17z M16,17h-4c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S16.6,17,16,17z M5.6,11l2.7-5.5C8.5,5.2,8.9,5,9.2,5h5.5c0.4,0,0.7,0.2,0.9,0.5l2.7,5.5H5.6z"
    },
    hash: {
        id: "hash", viewBox: "0 0 24 24",
        path: "M20,14h-4.3l0.7-4H20c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3.2l0.7-3.8c0.1-0.5-0.2-1.1-0.8-1.2c0,0,0,0-0.1,0c-0.5-0.1-1.1,0.2-1.2,0.7c0,0,0,0.1,0,0.1L14.7,8h-3.9l0.7-3.8c0.1-0.5-0.2-1.1-0.8-1.2c0,0,0,0-0.1,0c-0.5-0.1-1.1,0.2-1.2,0.7c0,0,0,0.1,0,0.1L8.7,8H4C3.4,8,3,8.4,3,9s0.4,1,1,1h4.3l-0.7,4H4c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.2l-0.7,3.8c-0.1,0.5,0.2,1.1,0.8,1.2c0,0,0,0,0.1,0c0.5,0.1,1.1-0.2,1.2-0.7c0,0,0-0.1,0-0.1L9.3,16h3.9l-0.7,3.8c-0.1,0.5,0.2,1.1,0.8,1.2c0,0,0,0,0.1,0c0.5,0.1,1.1-0.2,1.2-0.7c0,0,0-0.1,0-0.1l0.8-4.2H20c0.6,0,1-0.4,1-1S20.6,14,20,14z M9.7,14l0.7-4h3.9l-0.7,4H9.7z"
    },
    headphones: {
        id: "headphones", viewBox: "0 0 24 24",
        path: "M22,12.4V17c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-4,4-4c0.7,0,1.4,0.2,2,0.6v-1.2c0.1-4.5-3.5-8.3-8-8.4c-4.5,0.1-8.1,3.9-8,8.4v1.2C4.6,13.2,5.3,13,6,13c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4v-4.6C1.9,6.7,6.4,2.1,12,2C17.6,2.1,22.1,6.7,22,12.4z"
    },
    heart: {
        id: "heart", viewBox: "0 0 24 24",
        path: "M20.5,12.9l-7.8,7.8C12.5,20.9,12.3,21,12,21c-0.3,0-0.5-0.1-0.7-0.3l-7.8-7.8c-2-2-2-5.4,0-7.4c2-2,5.4-2,7.4,0L12,6.6l1.1-1.1c2-2,5.4-2,7.4,0C22.5,7.6,22.5,10.9,20.5,12.9z"
    },
    home: {
        id: "home", viewBox: "0 0 24 24",
        path: "M10,14h4v7h-4V14z M20.4,10.2l-7.7-7.9c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-7.7,7.9C3.2,10.6,3,11.1,3,11.6V20c0,1.1,0.8,1.9,1.9,2H8v-9c0-0.6,0.4-1,1-1h6c0.6,0,1,0.4,1,1v9h3.1c1.1-0.1,1.9-0.9,1.9-2v-8.4C21,11.1,20.8,10.6,20.4,10.2z"
    },
    image: {
        id: "image", viewBox: "0 0 24 24",
        path: "M18,3H6C4.3,3,3,4.3,3,6v12c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V6C21,4.3,19.7,3,18,3z M8,7c0.8,0,1.5,0.7,1.5,1.5S8.8,10,8,10S6.5,9.3,6.5,8.5S7.2,7,8,7z M19,17.8c0,0.6-0.4,1.1-1,1.2H6l7.6-6.8c0.3-0.2,0.7-0.2,0.9,0l4.5,4.5V17.8z"
    },
    image_2: {
        id: "image-2", viewBox: "0 0 24 24",
        path: "M18,3H6C4.3,3,3,4.3,3,6v12c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V6C21,4.3,19.7,3,18,3z M19,14.4l-3.2-2.7c-1-0.8-2.5-0.8-3.5,0L5,17.7V6c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1V14.4z M9.5,8.5C9.5,9.3,8.8,10,8,10S6.5,9.3,6.5,8.5S7.2,7,8,7S9.5,7.7,9.5,8.5z"
    },
    inbox: {
        id: "inbox", viewBox: "0 0 24 24",
        path: "M20.8,11.3l-3.3-6.7c-0.5-1-1.6-1.7-2.7-1.7H9.2C8.1,3,7.1,3.6,6.5,4.7l-3.3,6.7C3.1,11.6,3,11.9,3,12.2V18c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3v-5.8C21,11.9,20.9,11.6,20.8,11.3z M16,11c-0.6,0-1,0.4-1,1v2c0,0.6-0.4,1-1,1h-4c-0.6,0-1-0.4-1-1v-2c0-0.6-0.4-1-1-1H5.6l2.7-5.5C8.5,5.2,8.9,5,9.2,5h5.5c0.4,0,0.7,0.2,0.9,0.5l2.7,5.5H16z"
    },
    info: {
        id: "info", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,16c0,0.6-0.4,1-1,1s-1-0.4-1-1v-5c0-0.6,0.4-1,1-1s1,0.4,1,1V16z M12,9c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S12.6,9,12,9z"
    },

    keypad: {
        id: "keypad", viewBox: "0 0 24 24",
        path: "M8,5c0,1.7-1.3,3-3,3S2,6.7,2,5s1.3-3,3-3S8,3.3,8,5z M12,2c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S13.7,2,12,2z M19,8c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S17.3,8,19,8z M5,9c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S6.7,9,5,9z M12,9c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S13.7,9,12,9z M19,9c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S20.7,9,19,9z M5,16c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S6.7,16,5,16z M12,16c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S13.7,16,12,16z M19,16c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S20.7,16,19,16z"
    },

    layers: {
        id: "layers", viewBox: "0 0 24 24",
        path: "M3,7c-0.1-0.2,0-0.5,0.3-0.6L11.8,3c0.1,0,0.3,0,0.4,0l8.5,3.5c0.1,0,0.2,0.1,0.2,0.2c0.1,0.2,0,0.5-0.2,0.6l-8.5,4.6c-0.1,0.1-0.3,0.1-0.5,0L3.2,7.3C3.1,7.2,3.1,7.1,3,7z M20.7,10.7l-1.8-0.8l-6.6,3.6c-0.1,0.1-0.3,0.1-0.5,0L5.1,9.9l-1.8,0.8c-0.2,0.1-0.3,0.4-0.2,0.6c0,0.1,0.1,0.2,0.2,0.2l8.5,4.9c0.1,0.1,0.3,0.1,0.5,0l8.5-4.9c0.2-0.1,0.3-0.4,0.1-0.7C20.9,10.8,20.8,10.7,20.7,10.7z M21,15.3c-0.1-0.1-0.1-0.2-0.2-0.2l-1.6-0.7l-6.9,3.8c-0.1,0.1-0.3,0.1-0.5,0l-6.9-3.8l-1.6,0.7C3,15.2,3,15.5,3.1,15.8c0,0.1,0.1,0.2,0.2,0.2l8.5,5c0.2,0.1,0.3,0.1,0.5,0l8.5-5C21,15.8,21.1,15.5,21,15.3z"
    },
    layout: {
        id: "layout", viewBox: "0 0 24 24",
        path: "M21,6v2H3V6c0-1.7,1.3-3,3-3h12C19.7,3,21,4.3,21,6z M3,18c0,1.7,1.3,3,3,3h5V10H3V18z M13,21h5c1.7,0,3-1.3,3-3v-8h-8V21z"
    },
    link: {
        id: "link", viewBox: "0 0 24 24",
        path: "M9,11h6c0.6,0,1,0.4,1,1s-0.4,1-1,1H9c-0.6,0-1-0.4-1-1S8.4,11,9,11z M9,16H7.2c-2.1,0.1-4-1.5-4.2-3.6C2.8,10.2,4.4,8.2,6.6,8C6.7,8,6.9,8,7,8h2c0.6,0,1-0.4,1-1S9.6,6,9,6H7.2C4.1,6,1.5,8.2,1,11.2c-0.4,3.3,1.9,6.3,5.2,6.7C6.5,18,6.7,18,7,18h2c0.6,0,1-0.4,1-1S9.6,16,9,16z M23,11.2c-0.5-3.1-3.1-5.3-6.2-5.2h-1.5C14.4,6,14,6.5,14,7c0,0.6,0.4,1,1,1h1.8c2.1-0.1,4,1.5,4.2,3.6c0.2,2.2-1.4,4.1-3.6,4.4c-0.1,0-0.3,0-0.4,0h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c3.3,0,6-2.6,6-6C23.1,11.8,23,11.5,23,11.2z"
    },
    link45: {
        id: "link-45", viewBox: "0 0 24 24",
        path: "M14.7,9.3c0.4,0.4,0.4,1,0,1.4l-4,4c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4l4-4C13.7,8.9,14.3,8.9,14.7,9.3z M12.3,17.4L11,18.7c-1.5,1.5-3.9,1.7-5.6,0.4c-1.7-1.4-1.9-3.9-0.5-5.6c0.1-0.1,0.2-0.2,0.3-0.3l1.4-1.4c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-1.3,1.3c-2.2,2.1-2.5,5.6-0.7,8.1c2,2.7,5.8,3.1,8.5,1.1c0.2-0.2,0.4-0.3,0.6-0.5l1.4-1.4c0.4-0.4,0.4-1,0-1.4C13.3,17,12.7,17,12.3,17.4z M20.2,3.7c-0.2-0.2-0.4-0.3-0.6-0.5c-2.5-1.8-6-1.6-8.1,0.7L10.5,5C10,5.4,9.9,6,10.2,6.5c0,0,0,0.1,0.1,0.1c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0L13,5.3c1.5-1.5,3.9-1.7,5.6-0.4c1.7,1.4,2,3.9,0.6,5.6c-0.1,0.1-0.2,0.2-0.3,0.3l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0l1.4-1.4C22.6,9.9,22.6,6.1,20.2,3.7z"
    },
    linkedin: {
        id: "linkedin", viewBox: "0 0 24 24",
        path: "M21,14.2v5.9c0,0.5-0.4,0.9-0.9,0.9H18c-0.5,0-0.9-0.4-0.9-0.9v-5.8c0-1-0.7-1.9-1.8-2c-0.1,0-0.1,0-0.2,0c-1.1,0-1.9,0.9-1.9,1.9v5.9c0,0.5-0.4,0.9-0.9,0.9h-2.1c-0.5,0-0.9-0.4-0.9-0.9v-5.9c0,0,0,0,0,0c0-3.2,2.6-5.8,5.8-5.8c0,0,0,0,0,0C18.4,8.4,21,11,21,14.2z M6.6,9.3H3.9C3.4,9.3,3,9.7,3,10.2v9.9C3,20.6,3.4,21,3.9,21h2.7c0.5,0,0.9-0.4,0.9-0.9v-9.9C7.5,9.7,7.1,9.3,6.6,9.3z M5.3,3C4,3,3,4,3,5.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S6.5,3,5.3,3z"
    },
    list: {
        id: "list", viewBox: "0 0 24 24",
        path: "M4,6c0.6,0,1,0.4,1,1S4.6,8,4,8S3,7.6,3,7S3.4,6,4,6z M4,11c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S3.4,11,4,11z M4,16c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S3.4,16,4,16z M7.9,11h12.1c0.5,0,0.9,0.4,0.9,0.9v0.1c0,0.5-0.4,0.9-0.9,0.9H7.9C7.4,13,7,12.6,7,12.1v-0.1C7,11.4,7.4,11,7.9,11z M7.9,16h12.1c0.5,0,0.9,0.4,0.9,0.9v0.1c0,0.5-0.4,0.9-0.9,0.9H7.9C7.4,18,7,17.6,7,17.1v-0.1C7,16.4,7.4,16,7.9,16z M7.9,6h12.1C20.6,6,21,6.4,21,6.9v0.1C21,7.6,20.6,8,20.1,8H7.9C7.4,8,7,7.6,7,7.1V6.9C7,6.4,7.4,6,7.9,6z"
    },
    loader: {
        id: "loader", viewBox: "0 0 24 24",
        path: "M13,3v2c0,0.6-0.4,1-1,1s-1-0.4-1-1V3c0-0.6,0.4-1,1-1S13,2.4,13,3z M21,11h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1-0.4,1-1S21.6,11,21,11z M6,12c0-0.6-0.4-1-1-1H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h2C5.6,13,6,12.6,6,12z M6.2,5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4C6.5,8,6.7,8.1,7,8.1c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L6.2,5z M17,8.1c0.3,0,0.5-0.1,0.7-0.3l1.4-1.4c0.4-0.4,0.4-1,0-1.4c-0.4-0.3-0.9-0.4-1.3-0.1l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4C16.5,8,16.7,8.1,17,8.1z M12,18c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C13,18.4,12.6,18,12,18z M19.2,17.6l-1.5-1.4c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l1.4,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3C19.6,18.6,19.6,18,19.2,17.6C19.2,17.6,19.2,17.6,19.2,17.6z M6.3,16.2L6.3,16.2l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l1.4-1.4c0.4-0.4,0.4-1,0-1.4S6.7,15.8,6.3,16.2z"
    },
    lock: {
        id: "lock", viewBox: "0 0 24 24",
        path: "M13,15c0,0.6-0.4,1-1,1s-1-0.4-1-1s0.4-1,1-1S13,14.4,13,15z M20,11v8c0,1.7-1.3,3-3,3H7c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h1V6.1c0-2.2,1.8-4,4-4s4,1.8,4,4V8h1C18.7,8,20,9.3,20,11z M10,8h4V6.1C14,5,13.1,4,12,4c-1.1,0-2,1-2,2.1V8zM15,15c0-1.7-1.3-3-3-3s-3,1.3-3,3s1.3,3,3,3S15,16.7,15,15z"
    },
    login: {
        id: "login", viewBox: "0 0 24 24",
        path: "M20,5v14c0,0.6-0.4,1-1,1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h1V6h-1c-0.6,0-1-0.4-1-1s0.4-1,1-1h2C19.6,4,20,4.4,20,5zM11.8,7.4c-0.3-0.4-1-0.5-1.4-0.2s-0.5,1-0.2,1.4L12,11H4c-0.6,0-1,0.4-1,1s0.4,1,1,1h8.1l-1.7,2.4c-0.3,0.4-0.2,1.1,0.2,1.4c0,0,0,0,0,0C10.8,17,11,17,11.2,17c0.3,0,0.6-0.2,0.8-0.4l2.8-4c0.3-0.4,0.3-0.8,0-1.2L11.8,7.4z"
    },
    logout: {
        id: "logout", viewBox: "0 0 24 24",
        path: "M6,6v12h1c0.6,0,1,0.4,1,1s-0.4,1-1,1H5c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1S7.6,6,7,6H6z M20.8,11.4l-2.8-4c-0.3-0.4-0.9-0.6-1.4-0.2c-0.5,0.3-0.6,0.9-0.2,1.4c0,0,0,0,0,0l1.7,2.4H10c-0.6,0-1,0.4-1,1s0.4,1,1,1h8l-1.8,2.4c-0.3,0.4-0.2,1.1,0.2,1.4c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4l3-4C21.1,12.3,21.1,11.8,20.8,11.4z"
    },

    map: {
        id: "map", viewBox: "0 0 24 24",
        path: "M20.4,5.9l-4-1.8h-0.8L12,5.7L8.4,4.1h0L8.2,4H7.6l-4,1.8C3.2,6,3,6.4,3,6.8V19c0,0.3,0.2,0.7,0.5,0.8C3.6,19.9,3.8,20,4,20c0.1,0,0.3,0,0.4-0.1L8,18.3l3.6,1.6h0c0.2,0.1,0.5,0.1,0.7,0h0l3.6-1.6l3.6,1.6C19.7,20,19.9,20,20,20c0.2,0,0.4-0.1,0.5-0.2c0.3-0.2,0.5-0.5,0.5-0.8V6.8C21,6.4,20.8,6.1,20.4,5.9z M11,17.4l-2-0.9v-10l2,0.9V17.4z M19,17.5l-2-0.9v-10l2,0.9V17.5z"
    },
    maximize: {
        id: "maximize", viewBox: "0 0 24 24",
        path: "M20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3l-3.4-3.4c1.1-1.4,1.7-3.1,1.7-4.9c0-4.4-3.6-8-8-8s-8,3.6-8,8s3.6,8,8,8c1.8,0,3.5-0.6,4.9-1.7l3.4,3.4c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3z M13,12h-1v1c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h1V9c0-0.6,0.4-1,1-1s1,0.4,1,1v1h1c0.6,0,1,0.4,1,1S13.6,12,13,12z"
    },
    menu: {
        id: "menu", viewBox: "0 0 24 24",
        path: "M21,12L21,12c0,0.6-0.4,1-1,1H4c-0.5,0-1-0.4-1-1V12c0-0.5,0.4-1,1-1H20C20.6,11,21,11.4,21,12z M20,16H4c-0.5,0-1,0.4-1,1V17c0,0.5,0.4,1,1,1H20c0.5,0,1-0.4,1-1V17C21,16.4,20.6,16,20,16z M20,6H4C3.4,6,3,6.4,3,7V7c0,0.5,0.4,1,1,1H20c0.5,0,1-0.4,1-1V7C21,6.4,20.6,6,20,6z"
    },
    menu_2: {
        id: "menu-2", viewBox: "0 0 24 24",
        path: "M3,12c0-0.6,0.4-1,1-1s1,0.4,1,1s-0.4,1-1,1S3,12.6,3,12z M20.1,11H7.9C7.4,11,7,11.4,7,11.9v0.1C7,12.6,7.4,13,7.9,13h12.1c0.5,0,0.9-0.4,0.9-0.9v-0.1C21,11.4,20.6,11,20.1,11z M20.1,16H3.9C3.4,16,3,16.4,3,16.9v0.1C3,17.6,3.4,18,3.9,18h16.1c0.5,0,0.9-0.4,0.9-0.9v-0.1C21,16.4,20.6,16,20.1,16z M20.1,6H3.9C3.4,6,3,6.4,3,6.9v0.1C3,7.6,3.4,8,3.9,8h16.1C20.6,8,21,7.6,21,7.1V6.9C21,6.4,20.6,6,20.1,6z"
    },
    menu_arrow: {
        id: "menu-arrow", viewBox: "0 0 24 24",
        path: "M21,12L21,12c0,0.6-0.4,1-1,1H5.9l1.3,1.3c0,0,0,0,0,0c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0l-3-3c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4l3-3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L5.9,11H20C20.6,11,21,11.5,21,12z M20,17H4c-0.5,0-1,0.4-1,1V18c0,0.5,0.4,1,1,1H20c0.5,0,1-0.4,1-1V18C21,17.4,20.6,17,20,17z M20,5H4C3.4,5,3,5.4,3,6V6c0,0.5,0.4,1,1,1H20c0.5,0,1-0.4,1-1V6C21,5.4,20.6,5,20,5z"
    },
    message_circle: {
        id: "message-circle", viewBox: "0 0 24 24",
        path: "M19.1,5L19.1,5C15.2,1,8.8,1,4.9,4.9c-2.9,2.9-3.8,7.3-2.1,11c0.1,0.2,0.1,0.4,0.1,0.6L2,20.8c-0.1,0.3,0,0.7,0.3,0.9C2.5,21.9,2.7,22,3,22h0.2l4.3-0.9c0.2,0,0.4,0,0.6,0.1c5.1,2.2,11-0.2,13.1-5.2C22.9,12.2,22,7.8,19.1,5z M8,13c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S8.6,13,8,13z M12,13c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S12.6,13,12,13z M16,13c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S16.6,13,16,13z"
    },
    message_square: {
        id: "message-square", viewBox: "0 0 24 24",
        path: "M19,3H5C3.3,3,2,4.3,2,6v15c0,0.4,0.2,0.7,0.5,0.9C2.7,22,2.8,22,3,22c0.2,0,0.4,0,0.5-0.1L8,19.1C8.2,19,8.4,19,8.5,19H19c1.7,0,3-1.3,3-3V6C22,4.3,20.7,3,19,3z M8,12c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S8.6,12,8,12z M12,12c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S12.6,12,12,12z M16,12c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S16.6,12,16,12z"
    },
    mic: {
        id: "mic", viewBox: "0 0 24 24",
        path: "M8,11V6c0-2.2,1.8-4,4-4s4,1.8,4,4v5c0,2.2-1.8,4-4,4S8,13.2,8,11z M19,11c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,2.8-2.2,5-5,5s-5-2.2-5-5c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,3.5,2.6,6.4,6,6.9V20H8.9C8.4,20,8,20.4,8,20.9v0.2C8,21.6,8.4,22,8.9,22h6.2c0.5,0,0.9-0.4,0.9-0.9v-0.2c0-0.5-0.4-0.9-0.9-0.9H13v-2.1C16.4,17.4,19,14.5,19,11z"
    },
    mic_off: {
        id: "mic-off", viewBox: "0 0 24 24",
        path: "M15.6,12.8L8.1,5.3c0.4-1.8,2-3.2,3.9-3.2c2.2,0,4,1.7,4,4v5C16,11.6,15.9,12.2,15.6,12.8z M19,11c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,0.9-0.2,1.7-0.7,2.5l1.5,1.5C18.6,13.8,19,12.4,19,11z M12,15h0.2L8,10.8V11C8,13.2,9.8,15,12,15z M20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3l-16-16c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l16,16c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3z M15,20h-2v-2.1c0.6-0.1,1.1-0.2,1.7-0.4L13,15.9C12.7,16,12.4,16,12,16c-2.8,0-5-2.2-5-5c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,3.5,2.6,6.4,6,6.9V20H9c-0.6,0-1,0.4-1,1s0.4,1,1,1h6c0.6,0,1-0.4,1-1S15.6,20,15,20z"
    },
    minimize: {
        id: "minimize", viewBox: "0 0 24 24",
        path: "M20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3l-3.4-3.4c1.1-1.4,1.7-3.1,1.7-4.9c0-4.4-3.6-8-8-8s-8,3.6-8,8s3.6,8,8,8c1.8,0,3.5-0.6,4.9-1.7l3.4,3.4c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3z M13,12H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S13.6,12,13,12z"
    },
    minus: {
        id: "minus", viewBox: "0 0 24 24",
        path: "M20,12c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1s0.4-1,1-1h14C19.6,11,20,11.4,20,12z"
    },
    minus_circle: {
        id: "minus-circle", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M15,13H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S15.6,13,15,13z"
    },
    minus_square: {
        id: "minus-square", viewBox: "0 0 24 24",
        path: "M18,3H6C4.3,3,3,4.3,3,6v12c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V6C21,4.3,19.7,3,18,3z M15,13H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S15.6,13,15,13z"
    },
    monitor: {
        id: "monitor", viewBox: "0 0 24 24",
        path: "M22,6v5H2V6c0-1.7,1.3-3,3-3h14C20.7,3,22,4.3,22,6z M2,14c0,1.7,1.3,3,3,3h6v2H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4v-2h6c1.7,0,3-1.3,3-3v-1H2V14z"
    },
    moon: {
        id: "monitor", viewBox: "0 0 24 24",
        path: "M21.9,15.4c-0.5,1.4-1.3,2.6-2.3,3.6c-1.9,1.9-4.6,3-7.3,3h-0.1c-2.8,0-5.4-1.2-7.3-3.2c-3.7-3.9-3.8-10-0.3-14c1.1-1.2,2.5-2.2,4-2.7C9,2,9.4,2.1,9.7,2.3C9.9,2.6,10,3,9.9,3.3c-1.1,3.1-0.4,6.5,1.9,8.8c2.3,2.3,5.8,3,8.8,1.9c0.5-0.2,1.1,0.1,1.3,0.6C22,14.9,22,15.2,21.9,15.4z"
    },
    more_horizontal: {
        id: "more-horizontal", viewBox: "0 0 24 24",
        path: "M14,12c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S14,10.9,14,12z M19,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S20.1,10,19,10zM5,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S6.1,10,5,10z"
    },
    more_vertical: {
        id: "more-vertical", viewBox: "0 0 24 24",
        path: "M14,12c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S14,10.9,14,12z M12,7c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2S10.9,7,12,7zM12,17c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,17,12,17z"
    },
    move: {
        id: "move", viewBox: "0 0 24 24",
        path: "M22,12c0,0.3-0.1,0.5-0.3,0.7l-3,3C18.5,15.9,18.3,16,18,16c-0.3,0-0.5-0.1-0.7-0.3c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4l1.3-1.3H13v5.6l1.3-1.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-3,3C12.5,21.9,12.3,22,12,22c-0.3,0-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.3,1.3V13H5.4l1.3,1.3c0,0,0,0,0,0c0.4,0.4,0.4,1,0,1.4C6.5,15.9,6.3,16,6,16c-0.3,0-0.5-0.1-0.7-0.3l-3-3C2.1,12.5,2,12.3,2,12c0-0.3,0.1-0.5,0.3-0.7l3-3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L5.4,11H11V5.4L9.7,6.7c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l3-3C11.5,2.1,11.7,2,12,2c0.3,0,0.5,0.1,0.7,0.3l3,3c0,0,0,0,0,0c0.4,0.4,0.4,1,0,1.4C15.5,6.9,15.3,7,15,7c-0.3,0-0.5-0.1-0.7-0.3L13,5.4V11h5.6l-1.3-1.3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l3,3C21.9,11.5,22,11.7,22,12z"
    },
    music: {
        id: "music", viewBox: "0 0 24 24",
        path: "M19,4v11c0,0.2,0,0.3,0,0.5c0,0.5-0.1,1-0.3,1.5c-0.8,1.7-2.9,2.5-4.6,1.7c-1.7-0.8-2.5-2.9-1.7-4.6s2.9-2.5,4.6-1.7V5.3l-7,1.5V17c0,0.2,0,0.3,0,0.5c0,0.5-0.1,1-0.3,1.5c-0.8,1.7-2.9,2.5-4.6,1.7c-1.7-0.8-2.5-2.9-1.7-4.6c0.8-1.7,2.9-2.5,4.6-1.7V6c0-0.5,0.3-0.9,0.8-1l9-2c0.3-0.1,0.6,0,0.8,0.2C18.9,3.4,19,3.7,19,4z"
    },

    navigation: {
        id: "navigation", viewBox: "0 0 24 24",
        path: "M20.7,19.8C20.5,19.9,20.2,20,20,20c-0.2,0-0.4-0.1-0.5-0.2l-6.9-4.6c-0.3-0.2-0.8-0.2-1.1,0l-6.9,4.6c-0.5,0.3-1.1,0.2-1.4-0.3c-0.2-0.3-0.2-0.7-0.1-1l8-16c0.3-0.5,0.9-0.7,1.3-0.4c0.2,0.1,0.3,0.2,0.4,0.4l8,16C21.1,19,21,19.4,20.7,19.8z"
    },
    navigation45: {
        id: "navigation-2", viewBox: "0 0 24 24",
        path: "M20,5.3l-5.3,16c-0.1,0.4-0.6,0.7-1,0.7h-0.1c-0.5,0-0.8-0.4-0.9-0.8l-1.5-7.6c-0.1-0.4-0.4-0.7-0.8-0.8l-7.6-1.5c-0.5-0.1-0.9-0.6-0.8-1.2c0.1-0.4,0.3-0.6,0.7-0.8l16-5.3c0.5-0.2,1.1,0,1.3,0.5C20.1,4.8,20.1,5.1,20,5.3z"
    },
    npm: {
        id: "npm", viewBox: "0 0 24 24",
        path: "M21,6v12c0,1.7-1.3,3-3,3h-1V11h-4v10H6c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3h12C19.7,3,21,4.3,21,6z"
    },

    options_horizontal: {
        id: "options-horizontal", viewBox: "0 0 24 24",
        path: "M2,12c0-0.6,0.4-1,1-1h13.2c0.4-1.2,1.6-2,2.8-2c1.3,0,2.4,0.8,2.8,2c0.6,1.6-0.3,3.3-1.8,3.8c-1.6,0.6-3.3-0.3-3.8-1.8H3C2.4,13,2,12.6,2,12z M3,7h1.2C4.7,8.6,6.5,9.4,8,8.8c0.8-0.3,1.5-1,1.8-1.8H21c0.6,0,1-0.4,1-1s-0.4-1-1-1H9.8C9.3,3.4,7.5,2.6,6,3.2C5.1,3.5,4.5,4.2,4.2,5H3C2.4,5,2,5.4,2,6S2.4,7,3,7z M21,17h-7.2c-0.6-1.6-2.3-2.4-3.8-1.8c-0.8,0.3-1.5,1-1.8,1.8H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h5.2c0.6,1.6,2.3,2.4,3.8,1.8c0.8-0.3,1.5-1,1.8-1.8H21c0.6,0,1-0.4,1-1S21.6,17,21,17z"
    },
    options_vertical: {
        id: "options-vertical", viewBox: "0 0 24 24",
        path: "M8.8,16c0.6,1.6-0.2,3.3-1.8,3.8V21c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1.2c-0.8-0.3-1.5-1-1.8-1.8c-0.6-1.6,0.2-3.3,1.8-3.8V3c0-0.6,0.4-1,1-1s1,0.4,1,1v11.2C7.8,14.5,8.5,15.1,8.8,16z M19,10.2V3c0-0.6-0.4-1-1-1s-1,0.4-1,1v7.2c-1.6,0.6-2.4,2.3-1.8,3.8c0.3,0.8,1,1.5,1.8,1.8V21c0,0.6,0.4,1,1,1s1-0.4,1-1v-5.2c1.2-0.4,2-1.6,2-2.8C21,11.7,20.2,10.6,19,10.2z M12,2c-1.7,0-3,1.3-3,3c0,1.3,0.8,2.4,2,2.8V21c0,0.6,0.4,1,1,1s1-0.4,1-1V7.8c1.2-0.4,2-1.6,2-2.8C15,3.3,13.7,2,12,2z"
    },

    pantone: {
        id: "pantone", viewBox: "0 0 24 24",
        path: "M21,14.2V20c0,0.6-0.4,1-1,1H10l0.5-0.5l1.4-1.5l3.6-3.8l1.9-2H20C20.6,13.2,21,13.6,21,14.2z M18.2,9.3L14,5.4c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3l-0.8,0.9v10.7c0,0.2,0,0.4-0.1,0.6l6.5-6.9C18.6,10.3,18.6,9.7,18.2,9.3zM10.8,17.1c0,2.2-1.8,3.9-3.9,3.9C4.8,21,3,19.2,3,17.1V4c0-0.6,0.4-1,1-1h5.8c0.6,0,1,0.4,1,1V17.1z M8.8,15H5v2.1C5,18.1,5.9,19,6.9,19c1.1,0,1.9-0.9,1.9-1.9V15z M8.8,10H5v3h3.8V10z M8.8,5H5v3h3.8V5z"
    },
    paper_plane: {
        id: "paper-plane", viewBox: "0 0 24 24",
        path: "M20.9,3.7L20.9,3.7c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.3-0.2h-0.1C20.3,3.1,20.2,3,20,3h0c-0.1,0-0.2,0-0.3,0l-18,6c-0.5,0.2-0.8,0.7-0.6,1.3c0.1,0.3,0.3,0.5,0.6,0.6l8.5,2.8l2.8,8.5c0.2,0.5,0.7,0.8,1.3,0.6c0.3-0.1,0.5-0.3,0.6-0.6l6-18c0-0.1,0-0.2,0-0.3C21,3.9,21,3.8,20.9,3.7z M5.2,10l11.1-3.7l-5.6,5.6L5.2,10z M14,18.8l-1.9-5.6l5.6-5.6L14,18.8z"
    },
    pause_circle: {
        id: "pause-circle", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M10,15c0,0.6-0.4,1-1,1s-1-0.4-1-1V9c0-0.6,0.4-1,1-1s1,0.4,1,1V15z M16,15c0,0.6-0.4,1-1,1s-1-0.4-1-1V9c0-0.6,0.4-1,1-1s1,0.4,1,1V15z"
    },
    people: {
        id: "people", viewBox: "0 0 24 24",
        path: "M5,7c0-2.2,1.8-4,4-4s4,1.8,4,4s-1.8,4-4,4S5,9.2,5,7z M17,13c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S15.3,13,17,13z M17,14c-1.1,0-2.2,0.4-3.1,1c-2.7-2.7-7.2-2.7-9.9,0c-1.3,1.3-2,3.1-2,4.9c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1h5c0.6,0,1-0.4,1-1C22,16.2,19.8,14,17,14z"
    },
    percent: {
        id: "percent", viewBox: "0 0 24 24",
        path: "M8,11c1.9,0,3.5-1.6,3.5-3.5S9.9,4,8,4S4.5,5.6,4.5,7.5S6.1,11,8,11z M8,6c0.8,0,1.5,0.7,1.5,1.5S8.8,9,8,9S6.5,8.3,6.5,7.5S7.2,6,8,6z M16,14c-1.9,0-3.5,1.6-3.5,3.5S14.1,21,16,21s3.5-1.6,3.5-3.5S17.9,14,16,14z M16,19c-0.8,0-1.5-0.7-1.5-1.5S15.2,16,16,16s1.5,0.7,1.5,1.5S16.8,19,16,19z M19.7,5.5L5.5,19.7C5.4,19.9,5.1,20,4.9,20C4.4,20,4,19.6,4,19.1c0-0.2,0.1-0.5,0.3-0.6L18.5,4.3c0,0,0,0,0,0c0.3-0.3,0.9-0.3,1.3,0c0,0,0,0,0,0C20.1,4.6,20.1,5.2,19.7,5.5z"
    },
    person: {
        id: "person", viewBox: "0 0 24 24",
        path: "M8,7c0-2.2,1.8-4,4-4s4,1.8,4,4s-1.8,4-4,4S8,9.2,8,7z M12,13c-3.9,0-7,3.1-7,7c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1C19,16.1,15.9,13,12,13z"
    },
    person_add: {
        id: "person-add", viewBox: "0 0 24 24",
        path: "M22,7c0,0.6-0.4,1-1,1h-1v1c0,0.6-0.4,1-1,1s-1-0.4-1-1V8h-1c-0.6,0-1-0.4-1-1s0.4-1,1-1h1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v1h1C21.6,6,22,6.4,22,7z M10,11c2.2,0,4-1.8,4-4s-1.8-4-4-4S6,4.8,6,7S7.8,11,10,11z M10,13c-3.9,0-7,3.1-7,7c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1C17,16.1,13.9,13,10,13z"
    },
    person_delete: {
        id: "person-delete", viewBox: "0 0 24 24",
        path: "M21.2,9.7c-0.4,0.4-1.1,0.4-1.5,0L19,9l-0.7,0.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l0.7-0.7l-0.7-0.7c-0.4-0.4-0.4-1.1,0-1.5c0.4-0.4,1.1-0.4,1.5,0L19,6l0.7-0.7c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-0.7,0.7l0.7,0.7C21.6,8.6,21.6,9.3,21.2,9.7z M10,11c2.2,0,4-1.8,4-4s-1.8-4-4-4S6,4.8,6,7S7.8,11,10,11z M10,13c-3.9,0-7,3.1-7,7c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1C17,16.1,13.9,13,10,13z"
    },
    person_done: {
        id: "person-done", viewBox: "0 0 24 24",
        path: "M21.7,5.7l-2.6,3C18.9,8.9,18.7,9,18.4,9c-0.3,0-0.6-0.1-0.8-0.3l-1.4-1.6c-0.4-0.4-0.3-1,0.1-1.4c0.4-0.4,1-0.3,1.4,0.1l0.6,0.7l1.9-2.1c0.4-0.4,1-0.5,1.4-0.1C22.1,4.6,22.1,5.2,21.7,5.7z M10,11c2.2,0,4-1.8,4-4s-1.8-4-4-4S6,4.8,6,7S7.8,11,10,11zM10,13c-3.9,0-7,3.1-7,7c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1C17,16.1,13.9,13,10,13z"
    },
    person_remove: {
        id: "person-remove", viewBox: "0 0 24 24",
        path: "M22,7c0,0.6-0.4,1-1,1h-4c-0.6,0-1-0.4-1-1s0.4-1,1-1h4C21.6,6,22,6.4,22,7z M10,11c2.2,0,4-1.8,4-4s-1.8-4-4-4S6,4.8,6,7S7.8,11,10,11z M10,13c-3.9,0-7,3.1-7,7c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1C17,16.1,13.9,13,10,13z"
    },
    phone: {
        id: "phone", viewBox: "0 0 24 24",
        path: "M22,17.5c0,2.5-2.1,4.6-4.6,4.5C8.9,22,2,15.1,2,6.6C2,4.1,4.1,2,6.6,2c0.3,0,0.5,0,0.8,0.1c0.2,0,0.5,0.1,0.7,0.2C8.4,2.4,8.7,2.7,8.7,3l1.4,6c0.1,0.3,0,0.7-0.3,0.9c-0.1,0.1-0.1,0.2-1.4,0.8c1,2.2,2.7,3.9,4.9,4.9c0.7-1.2,0.7-1.3,0.8-1.4c0.2-0.2,0.6-0.3,0.9-0.3l6,1.4c0.3,0.1,0.6,0.3,0.7,0.6c0.1,0.2,0.1,0.5,0.2,0.7C22,17,22,17.2,22,17.5z"
    },
    phone_call: {
        id: "phone-call", viewBox: "0 0 24 24",
        path: "M12,7c0-0.6,0.4-1,1-1c2.8,0,5,2.2,5,5c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-1.7-1.3-3-3-3C12.4,8,12,7.6,12,7z M13,4c3.9,0,7,3.1,7,7c0,0.6,0.4,1,1,1s1-0.4,1-1c0-5-4-9-9-9c-0.6,0-1,0.4-1,1S12.4,4,13,4z M21.8,15.9c-0.1-0.3-0.4-0.6-0.7-0.6l-6-1.4c-0.3-0.1-0.7,0-0.9,0.3c-0.1,0.1-0.1,0.1-0.8,1.4c-2.2-1-3.9-2.7-4.9-4.9C9.7,10,9.7,10,9.8,9.8c0.2-0.2,0.3-0.6,0.3-0.9L8.7,3C8.7,2.7,8.4,2.4,8.1,2.3C7.9,2.2,7.6,2.1,7.4,2.1C7.1,2,6.9,2,6.6,2C4.1,2,2,4.1,2,6.6C2,15.1,8.9,22,17.4,22c2.5,0,4.6-2.1,4.6-4.6c0-0.3,0-0.5-0.1-0.8C21.9,16.4,21.8,16.1,21.8,15.9z"
    },
    phone_missed: {
        id: "phone-call-missed", viewBox: "0 0 24 24",
        path: "M22,17.4c0,2.5-2.1,4.6-4.6,4.6C8.9,22,2,15.1,2,6.6C2,4.1,4.1,2,6.6,2c0.3,0,0.5,0,0.8,0.1c0.2,0,0.5,0.1,0.7,0.2C8.4,2.4,8.7,2.7,8.7,3l1.4,5.9c0.1,0.3,0,0.7-0.3,0.9C9.7,10,9.7,10,8.4,10.6c1,2.2,2.7,3.9,4.9,4.9c0.7-1.2,0.7-1.3,0.8-1.4c0.2-0.2,0.6-0.3,0.9-0.3l6,1.4c0.3,0.1,0.6,0.3,0.7,0.6c0.1,0.2,0.1,0.5,0.2,0.7C22,16.9,22,17.1,22,17.4z M16.5,6.5l-0.7,0.7c-0.4,0.4-0.4,1.1,0,1.5c0.4,0.4,1.1,0.4,1.5,0L18,8l0.7,0.7c0.4,0.4,1.1,0.4,1.5,0c0.4-0.4,0.4-1.1,0-1.5l-0.7-0.7l0.7-0.7c0.4-0.4,0.4-1.1,0-1.5s-1.1-0.4-1.5,0L18,5l-0.7-0.7c-0.4-0.4-1.1-0.4-1.5,0c-0.4,0.4-0.4,1.1,0,1.5L16.5,6.5z"
    },
    phone_off: {
        id: "phone-call-missed", viewBox: "0 0 24 24",
        path: "M5.3,16.1C3.2,13.4,2,10,2,6.6C2,4.1,4.1,2,6.6,2c0.3,0,0.5,0,0.8,0.1c0.2,0,0.5,0.1,0.7,0.2C8.4,2.4,8.7,2.7,8.7,3l1.4,5.9c0.1,0.3,0,0.7-0.3,0.9C9.7,10,9.7,10,8.5,10.6c0.2,0.5,0.5,1,0.8,1.4L5.3,16.1z M21.9,16.6c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.3-0.4-0.6-0.7-0.6l-6-1.4c-0.3-0.1-0.7,0-0.9,0.3c-0.1,0.1-0.1,0.1-0.8,1.4c-0.5-0.2-1-0.5-1.4-0.8l-4,4c2.7,2.1,6.1,3.3,9.5,3.3c2.5,0,4.6-2.1,4.6-4.6C22,17.1,22,16.9,21.9,16.6z M19.7,4.3C19.7,4.3,19.7,4.3,19.7,4.3c-0.3-0.3-0.9-0.3-1.3,0c0,0,0,0,0,0L4.3,18.5C4.1,18.6,4,18.9,4,19.1C4,19.6,4.4,20,4.9,20c0.2,0,0.5-0.1,0.6-0.3L19.7,5.5C20.1,5.2,20.1,4.6,19.7,4.3z"
    },
    pie_chart: {
        id: "pie-chart", viewBox: "0 0 24 24",
        path: "M13.7,9.5V2.8C13.7,2.4,14,2,14.5,2C18.6,2,22,5.4,22,9.5c0,0.5-0.4,0.8-0.8,0.8h-6.7C14,10.3,13.7,10,13.7,9.5z M21.2,12C21.1,12,21.1,12,21.2,12l-8.2,0c-0.5,0-0.9-0.4-0.9-0.9V2.9C12,2.4,11.6,2,11.1,2c0,0-0.1,0-0.1,0C5.5,2.6,1.5,7.5,2.1,13c0.6,5.5,5.5,9.5,11,8.9c4.7-0.5,8.4-4.2,8.9-8.9C22,12.5,21.7,12,21.2,12z"
    },
    pie_chart_2: {
        id: "pie-chart-2", viewBox: "0 0 24 24",
        path: "M14.5,10.3h6.7c0.5,0,0.8-0.4,0.8-0.8C22,5.4,18.6,2,14.5,2c-0.5,0-0.8,0.4-0.8,0.8v6.7C13.7,10,14,10.3,14.5,10.3zM15.3,3.7c2.6,0.4,4.6,2.4,4.9,4.9h-4.9V3.7z M22,13c-0.5,4.7-4.2,8.4-8.9,8.9c-5.5,0.6-10.4-3.4-11-8.9C1.5,7.5,5.5,2.6,11,2c0,0,0.1,0,0.1,0C11.6,2,12,2.4,12,2.9v8.2c0,0.5,0.4,0.9,0.9,0.9h8.2c0,0,0.1,0,0.1,0C21.7,12,22,12.5,22,13z"
    },
    pin: {
        id: "pin", viewBox: "0 0 24 24",
        path: "M13.5,9.5c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5S11.2,8,12,8S13.5,8.7,13.5,9.5z M20,9.9c0,5.5-7,11.6-7.3,11.8c-0.4,0.3-0.9,0.3-1.3,0C11,21.5,4,15.4,4,9.9C4,5.5,7.6,2,12,2C16.4,2,20,5.5,20,9.9z M15.5,9.5C15.5,7.6,13.9,6,12,6S8.5,7.6,8.5,9.5S10.1,13,12,13S15.5,11.4,15.5,9.5z"
    },
    play_circle: {
        id: "play-circle", viewBox: "0 0 24 24",
        path: "M11.5,9.4l2.8,2.6l-2.8,2.6V9.4z M22,12c0,5.5-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2S22,6.5,22,12z M16,10.8l-3.6-3.4c-0.5-0.5-1.2-0.6-1.8-0.3c-0.6,0.2-1,0.8-1,1.5v6.7c0,0.7,0.4,1.2,1,1.5c0.2,0.1,0.5,0.1,0.7,0.2c0.4,0,0.8-0.2,1.2-0.5l3.6-3.4c0,0,0.1-0.1,0.1-0.1C16.7,12.4,16.7,11.4,16,10.8z"
    },
    plus: {
        id: "plus", viewBox: "0 0 24 24",
        path: "M20,12c0,0.6-0.4,1-1,1h-6v6c0,0.6-0.4,1-1,1s-1-0.4-1-1v-6H5c-0.6,0-1-0.4-1-1s0.4-1,1-1h6V5c0-0.6,0.4-1,1-1s1,0.4,1,1v6h6C19.6,11,20,11.4,20,12z"
    },
    plus_circle: {
        id: "plus-circle", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M15,13h-2v2c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h2V9c0-0.6,0.4-1,1-1s1,0.4,1,1v2h2c0.6,0,1,0.4,1,1S15.6,13,15,13z"
    },
    plus_square: {
        id: "plus-square", viewBox: "0 0 24 24",
        path: "M18,3H6C4.3,3,3,4.3,3,6v12c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V6C21,4.3,19.7,3,18,3z M15,13h-2v2c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h2V9c0-0.6,0.4-1,1-1s1,0.4,1,1v2h2c0.6,0,1,0.4,1,1S15.6,13,15,13z"
    },
    power: {
        id: "power", viewBox: "0 0 24 24",
        path: "M11,12V2c0-0.6,0.4-1,1-1s1,0.4,1,1v10c0,0.6-0.4,1-1,1S11,12.6,11,12z M20.9,7.4c-1-1.8-2.5-3.3-4.3-4.3c-0.5-0.3-1.1-0.1-1.4,0.4C15,4,15.2,4.6,15.7,4.9c3.9,2,5.5,6.9,3.4,10.8c-2,3.9-6.9,5.5-10.8,3.4c-3.9-2-5.5-6.9-3.4-10.8c0.8-1.5,2-2.7,3.4-3.4C8.8,4.6,9,4,8.8,3.5C8.5,3,7.9,2.9,7.4,3.1c-4.9,2.5-6.8,8.6-4.3,13.5c2.5,4.9,8.6,6.8,13.5,4.3C21.5,18.3,23.4,12.3,20.9,7.4z"
    },
    price_tag: {
        id: "price-tag", viewBox: "0 0 24 24",
        path: "M21.5,11.6L15,5.2c-0.2-0.2-0.4-0.3-0.6-0.3L5.1,4C4.8,4,4.5,4.1,4.3,4.3C4.1,4.5,4,4.8,4,5.1l0.9,9.3c0,0.2,0.1,0.4,0.3,0.6l6.4,6.4c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5l7.3-7.3C22.2,13.4,22.2,12.3,21.5,11.6z M11.6,11.6L11.6,11.6c-0.6,0.6-1.5,0.6-2.1,0C8.9,11,8.9,10,9.4,9.5c0.6-0.6,1.5-0.6,2.1,0c0,0,0,0,0,0C12.1,10,12.1,11,11.6,11.6z"
    },
    printer: {
        id: "printer", viewBox: "0 0 24 24",
        path: "M19.4,7H18V5c0-1.1-0.8-2-1.8-2H7.8C6.8,3,6,3.9,6,5v2H4.6C3.2,7,2,8.2,2,9.7v6.7C2,17.8,3.2,19,4.6,19h0.9c0,1.1,0.9,2,2,2h9c1.1,0,2-0.9,2-2h0.9c1.5,0,2.6-1.2,2.6-2.7V9.7C22,8.2,20.8,7,19.4,7z M8,5h8v2H8V5z M16.5,19h-9v-4h9V19z"
    },

    question_mark: {
        id: "question-mark", viewBox: "0 0 24 24",
        path: "M17,9c0,2.4-1.7,4.4-4,4.9V15c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2c0-0.6,0.4-1,1-1c1.7,0,3-1.3,3-3s-1.3-3-3-3S9,7.3,9,9c0,0.6-0.4,1-1,1S7,9.6,7,9c0-2.8,2.2-5,5-5S17,6.2,17,9z M12,18c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S12.6,18,12,18z"
    },
    question_mark_circle: {
        id: "question-mark-circle", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,18c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S12.6,18,12,18z M13,12.8V14c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2c0-0.6,0.4-1,1-1c0.8,0,1.5-0.7,1.5-1.5S12.8,8,12,8s-1.5,0.7-1.5,1.5c0,0.6-0.4,1-1,1s-1-0.4-1-1C8.5,7.6,10.1,6,12,6c1.9,0,3.5,1.6,3.5,3.5C15.5,11,14.5,12.4,13,12.8z"
    },

    radio: {
        id: "radio", viewBox: "0 0 24 24",
        path: "M14.8,10c0.5,1.6-0.3,3.3-1.8,3.8c0,0.1,0,0.1,0,0.2v6c0,0.6-0.4,1-1,1s-1-0.4-1-1v-6c0-0.1,0-0.1,0-0.2c-1.2-0.4-2-1.6-2-2.8c0-1.7,1.3-3,3-3C13.3,8,14.4,8.8,14.8,10z M3.5,11c0.1-2,1-4,2.6-5.2c0.4-0.4,0.5-1,0.1-1.4c-0.4-0.4-1-0.5-1.4-0.1C2.8,5.9,1.6,8.4,1.5,11c0.1,2.6,1.3,5.1,3.4,6.8c0.4,0.4,1.1,0.3,1.4-0.1c0.4-0.4,0.3-1.1-0.1-1.4C4.5,15,3.6,13,3.5,11z M16.6,6.2c-0.5-0.3-1.1-0.2-1.4,0.3C15,6.9,15,7.4,15.4,7.8c1,0.8,1.6,2,1.6,3.2c0,1.3-0.6,2.5-1.6,3.2c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.5,0.3,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2c1.5-1.2,2.3-2.9,2.4-4.8C19,9.1,18.1,7.4,16.6,6.2zM8.6,7.8c0.4-0.4,0.5-1,0.1-1.4c-0.4-0.4-1-0.5-1.4-0.1C5.9,7.4,5,9.1,5,11c0,1.9,0.9,3.6,2.4,4.8c0.4,0.3,1,0.3,1.4-0.1c0.3-0.4,0.3-1-0.1-1.4c-1-0.8-1.6-2-1.6-3.2C7,9.7,7.6,8.5,8.6,7.8z M19.1,4.2c-0.4-0.4-1.1-0.3-1.4,0.1c-0.4,0.4-0.3,1.1,0.1,1.4C19.5,7,20.4,9,20.5,11c-0.1,2-1,4-2.6,5.2c-0.4,0.4-0.5,1-0.1,1.4c0.4,0.4,1,0.5,1.4,0.1c2.1-1.6,3.3-4.1,3.4-6.8C22.4,8.4,21.2,5.9,19.1,4.2z"
    },
    radio_button_off: {
        id: "radio-button-off", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20z"
    },
    radio_button_on: {
        id: "radio-button-on", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20z M17,12c0,2.8-2.2,5-5,5s-5-2.2-5-5s2.2-5,5-5S17,9.2,17,12z"
    },
    recording: {
        id: "recording", viewBox: "0 0 24 24",
        path: "M22,12c0,2.2-1.8,4-4,4H6c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4c0,0.7-0.2,1.4-0.6,2h5.1c-0.4-0.6-0.6-1.3-0.6-2c0-2.2,1.8-4,4-4S22,9.8,22,12z"
    },
    refresh: {
        id: "refresh", viewBox: "0 0 24 24",
        path: "M21,14.7c-1.2,3.8-4.8,6.4-8.8,6.3c-5,0-9.1-4-9.2-9c0.1-5,4.2-9,9.2-9c2.2,0,4.4,0.8,6,2.3l0.3-1.5c0.1-0.6,0.6-0.9,1.2-0.8c0.6,0.1,0.9,0.6,0.8,1.2l-0.8,4c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2l-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1C19,9,18.9,9,18.8,9h-0.2l-4.2-0.7c0,0,0,0,0,0c-0.5-0.1-0.9-0.6-0.8-1.1c0,0,0,0,0,0c0.1-0.5,0.6-0.9,1.1-0.8l2.2,0.4c-1.3-1.1-3-1.7-4.7-1.7C8.3,5,5.1,8.1,5,12c0.1,3.9,3.3,7,7.2,7c3.1,0,5.9-2,6.9-4.9c0.2-0.5,0.7-0.8,1.3-0.7c0,0,0,0,0,0C20.9,13.6,21.2,14.2,21,14.7z"
    },
    repeat: {
        id: "repeat", viewBox: "0 0 24 24",
        path: "M17.9,5h-12l1.3-1.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-3,3c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0l3,3c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0L5.9,7h12c0.9,0,1.6,0.7,1.6,1.5c0,0,0,0,0,0V11c0,0.6,0.4,1,1,1s1-0.4,1-1V8.5C21.5,6.6,19.9,5,17.9,5C17.9,5,17.9,5,17.9,5z M18.2,14.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l1.3,1.3h-12c-0.9,0-1.6-0.7-1.6-1.5c0,0,0,0,0,0V13c0-0.6-0.4-1-1-1s-1,0.4-1,1v2.5c0,2,1.6,3.5,3.6,3.5c0,0,0,0,0,0h12l-1.3,1.3c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0l3-3c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0L18.2,14.3z"
    },
    rewind_left: {
        id: "rewind-left", viewBox: "0 0 24 24",
        path: "M19.5,7.8v8.4c0,0.7-0.4,1.3-1,1.6c-0.3,0.1-0.6,0.2-0.9,0.2c-0.5,0-0.9-0.2-1.3-0.5l-4.7-3.9v2.6c0,0.7-0.4,1.3-1,1.6C10.2,17.9,9.9,18,9.5,18c-0.5,0-0.9-0.2-1.3-0.5l-5.1-4.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.7-0.5-1.8,0.3-2.4l5.1-4.2C8.9,6,9.7,5.9,10.5,6.2c0.6,0.3,1,0.9,1,1.6v2.6l4.7-3.9c0.6-0.5,1.5-0.6,2.2-0.3C19.1,6.5,19.5,7.1,19.5,7.8z"
    },
    rewind_right: {
        id: "rewind-right", viewBox: "0 0 24 24",
        path: "M20.9,13.3l-5.1,4.2c-0.4,0.3-0.8,0.5-1.3,0.5c-0.3,0-0.6-0.1-0.9-0.2c-0.6-0.3-1-0.9-1-1.6v-2.6l-4.7,3.9C7.4,17.8,6.9,18,6.5,18c-0.3,0-0.6-0.1-0.9-0.2c-0.6-0.3-1-0.9-1-1.6V7.8c0-0.7,0.4-1.3,1-1.6C6.3,5.9,7.1,6,7.8,6.5l4.7,3.9V7.8c0-0.7,0.4-1.3,1-1.6c0.7-0.3,1.6-0.2,2.2,0.3l5.1,4.2c0.1,0.1,0.2,0.2,0.3,0.3C21.7,11.7,21.6,12.7,20.9,13.3z"
    },

    save: {
        id: "save", viewBox: "0 0 24 24",
        path: "M10,17h4v4h-4V17z M20.1,8.7l-4.8-4.8C14.7,3.3,14,3,13.2,3H10v6h5c0.6,0,1,0.4,1,1s-0.4,1-1,1H9c-0.6,0-1-0.4-1-1V3H6C4.3,3,3,4.3,3,6v12c0,1.7,1.3,3,3,3h2v-4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2v4h2c1.7,0,3-1.3,3-3v-7.2C21,10,20.7,9.3,20.1,8.7z"
    },
    scissors: {
        id: "scissors", viewBox: "0 0 24 24",
        path: "M20.2,5.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-6.3,6.3L9.2,7.3C9.4,6.9,9.5,6.4,9.5,6c0-1.7-1.3-3-3-3s-3,1.3-3,3s1.3,3,3,3c0.4,0,0.9-0.1,1.3-0.3l3.3,3.3l-3.3,3.3C7.4,15.1,7,15,6.5,15c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3c0-0.4-0.1-0.9-0.3-1.3L20.2,5.7z M6.5,7c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S7.1,7,6.5,7z M6.5,19c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S7.1,19,6.5,19z M20.2,19.7C20.2,19.7,20.2,19.7,20.2,19.7c-0.4,0.4-1,0.4-1.4,0l-5-5c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l5,5c0,0,0,0,0,0C20.6,18.7,20.6,19.3,20.2,19.7z"
    },
    search: {
        id: "search", viewBox: "0 0 24 24",
        path: "M20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3l-3.4-3.4c1.1-1.4,1.7-3.1,1.7-4.9c0-4.4-3.6-8-8-8s-8,3.6-8,8s3.6,8,8,8c1.8,0,3.5-0.6,4.9-1.7l3.4,3.4c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3z M11,17c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S14.3,17,11,17z"
    },
    settings: {
        id: "settings", viewBox: "0 0 24 24",
        path: "M13.5,12c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5S13.5,11.2,13.5,12z M22,11.1V13c0,0.9-0.7,1.6-1.6,1.7h-1.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.3,0,0.4l0.7,0.7c0,0,0,0,0,0c0.6,0.7,0.6,1.7,0,2.4l-1.3,1.3c-0.3,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.9-0.2-1.2-0.5l-0.8-0.8c-0.1-0.1-0.3-0.1-0.4,0c-0.2,0.1-0.3,0.2-0.3,0.3v1.1c0,0.9-0.8,1.7-1.7,1.7H11c-0.9,0-1.6-0.7-1.7-1.6v-1.1c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.1-0.4,0L8,19.7c0,0,0,0,0,0c-0.7,0.6-1.7,0.6-2.4,0l-1.3-1.4c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.4,0.2-0.9,0.5-1.2l0.8-0.7c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.2-0.2-0.3-0.3-0.3H3.7c-0.9,0-1.7-0.8-1.7-1.7V11c0-0.9,0.8-1.7,1.7-1.7h1C4.8,9.4,5,9.3,5,9.2C5.1,9,5.1,8.9,5,8.7L4.3,8c0,0,0,0,0,0c-0.6-0.7-0.6-1.7,0-2.4l1.4-1.3c0.3-0.3,0.7-0.5,1.2-0.5c0.4,0,0.9,0.2,1.2,0.5l0.7,0.8c0.1,0.1,0.3,0.1,0.4,0C9.3,5,9.4,4.9,9.4,4.8V3.7c0-0.9,0.8-1.7,1.7-1.7H13c0.9,0,1.6,0.8,1.6,1.7v1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.1,0.4,0L16,4.3c0,0,0,0,0,0c0.7-0.6,1.7-0.6,2.4,0l1.3,1.4c0.3,0.3,0.5,0.7,0.5,1.2c0,0.4-0.2,0.9-0.5,1.2l-0.8,0.7c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.2,0.2,0.3,0.3,0.3h1.1C21.2,9.4,22,10.1,22,11.1z M15.5,12c0-1.9-1.6-3.5-3.5-3.5S8.5,10.1,8.5,12s1.6,3.5,3.5,3.5S15.5,13.9,15.5,12z"
    },
    settings_2: {
        id: "settings-2", viewBox: "0 0 24 24",
        path: "M13.5,12c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5S13.5,11.2,13.5,12z M20.4,13.3l-0.2,0.1c-0.6,0.2-1,0.7-1.2,1.3c-0.2,0.6-0.1,1.2,0.2,1.7l0.3,0.4c0.7,1,0.5,2.5-0.5,3.2l-2,1.5c0,0-0.1,0.1-0.1,0.1c-1,0.7-2.4,0.4-3.1-0.6l-0.1-0.2c-0.3-0.5-0.9-0.8-1.5-0.8c-0.6,0-1.1,0.3-1.4,0.8L10.5,21c-0.3,0.5-0.9,0.9-1.5,1c-0.1,0-0.3,0-0.4,0c-0.5,0-1-0.1-1.3-0.4L5.2,20c-1-0.8-1.3-2.3-0.5-3.3c0.4-0.5,0.5-1.2,0.2-1.9l-0.1-0.2c-0.2-0.6-0.6-1-1.1-1.2H3.6c-1.2-0.4-1.9-1.7-1.5-2.9l0.8-2.6C3.1,7.3,3.5,6.9,4,6.6c0.5-0.3,1.1-0.3,1.7-0.1C6.3,6.7,7,6.6,7.5,6.2l0.1-0.1c0.5-0.4,0.7-0.9,0.7-1.5V4.3c0-1.3,1-2.3,2.3-2.3h2.6c0.6,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1.1,0.7,1.7v0.3c0,0.6,0.2,1.1,0.7,1.4l0.1,0.1c0.5,0.3,1,0.4,1.6,0.3l0.3-0.1c0,0,0.1,0,0.1,0c1.2-0.4,2.5,0.3,2.8,1.5l0.8,2.5C22.3,11.5,21.6,12.8,20.4,13.3z M15.5,12c0-1.9-1.6-3.5-3.5-3.5S8.5,10.1,8.5,12s1.6,3.5,3.5,3.5S15.5,13.9,15.5,12z"
    },
    shake: {
        id: "shake", viewBox: "0 0 24 24",
        path: "M6.1,16.2c0.4,0.4,0.5,1,0.1,1.4C6.1,17.9,5.8,18,5.5,18c-0.2,0-0.5-0.1-0.6-0.2c-2.1-1.6-3.3-4.1-3.4-6.8c0-2.6,1.3-5.1,3.4-6.8c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4C6.3,5.7,6.2,5.7,6.1,5.8C4.5,7,3.6,8.9,3.5,11C3.6,13.1,4.5,15,6.1,16.2z M12,2c-0.6,0-1,0.4-1,1v4.1C11.3,7.1,11.7,7,12,7c0.3,0,0.7,0.1,1,0.1V3C13,2.4,12.6,2,12,2z M11,14.9V20c0,0.6,0.4,1,1,1s1-0.4,1-1v-5.1c-0.3,0.1-0.7,0.1-1,0.1C11.7,15,11.3,14.9,11,14.9z M16.6,6.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.2,1.1,0.3,1.4c1,0.8,1.6,2,1.6,3.2c0,1.3-0.6,2.5-1.6,3.2c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2c1.5-1.2,2.3-2.9,2.4-4.8C19,9.1,18.1,7.4,16.6,6.2z M8.6,7.8c0.1,0,0.1-0.1,0.2-0.1c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0C5.9,7.4,5,9.1,5,11c0,1.9,0.9,3.6,2.4,4.8C7.5,15.9,7.8,16,8,16c0.3,0,0.6-0.1,0.8-0.4c0.3-0.4,0.3-1-0.1-1.4c-1-0.8-1.6-2-1.6-3.2C7,9.7,7.6,8.5,8.6,7.8z M19.1,4.2C19.1,4.2,19,4.1,19,4.1c-0.5-0.3-1.1-0.2-1.4,0.3s-0.2,1.1,0.3,1.4c1.6,1.3,2.6,3.2,2.6,5.2c-0.1,2.1-1,4-2.6,5.2c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2c2.1-1.6,3.3-4.1,3.4-6.8C22.5,8.4,21.2,5.9,19.1,4.2z M12,10c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S12.6,10,12,10z"
    },
    share: {
        id: "share", viewBox: "0 0 24 24",
        path: "M21,18c0,1.7-1.3,3-3,3s-3-1.3-3-3v-0.3l-7.9-3.5c-1.2,1.1-3,1.1-4.2,0c-1.2-1.2-1.2-3.1,0-4.2c1.2-1.2,3.1-1.2,4.2,0L15,6.3V6c0-0.8,0.3-1.5,0.9-2.1c1.2-1.2,3.1-1.2,4.2,0s1.2,3.1,0,4.2c-1.2,1.2-3.1,1.2-4.2,0L8,11.7V12v0.3l7.9,3.5c0.6-0.6,1.3-0.9,2.1-0.9C19.7,15,21,16.3,21,18z"
    },
    shield: {
        id: "shield", viewBox: "0 0 24 24",
        path: "M21,8.1v0.1c0,5.5-3,10.5-7.7,13.2L13,21.6c-0.3,0.2-0.6,0.3-1,0.3c-0.3,0-0.7-0.1-1-0.3l-0.3-0.2C5.9,18.7,3,13.7,3,8.2V8.1c0-0.7,0.4-1.4,1-1.8l7-3.9c0.6-0.4,1.4-0.4,2,0l7,3.9C20.6,6.7,21,7.4,21,8.1z"
    },
    shield_off: {
        id: "shield-off", viewBox: "0 0 24 24",
        path: "M3.7,6.6l12.6,12.6c-0.9,0.9-1.9,1.6-3,2.3L13,21.6c-0.6,0.4-1.4,0.4-2,0l-0.3-0.2C6,18.7,3,13.7,3,8.2V8.1C3,7.5,3.3,6.9,3.7,6.6z M21,8.2V8.1c0-0.7-0.4-1.4-1-1.8l-7-3.9c-0.6-0.4-1.4-0.4-2,0L7.3,4.5L18.8,16C20.3,13.7,21,11,21,8.2zM20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3l-16-16c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l16,16c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3z"
    },
    shopping_bag: {
        id: "shopping-bag", viewBox: "0 0 24 24",
        path: "M20.1,6.7l-2.8-2.8C16.7,3.3,16,3,15.2,3H8.8C8,3,7.3,3.3,6.7,3.9L3.9,6.7C3.3,7.3,3,8,3,8.8V18c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V8.8C21,8,20.7,7.3,20.1,6.7z M12,16c-2.2,0-4-1.8-4-4c0-0.6,0.4-1,1-1s1,0.4,1,1c0,1.1,0.9,2,2,2s2-0.9,2-2c0-0.6,0.4-1,1-1s1,0.4,1,1C16,14.2,14.2,16,12,16z M6.4,7l1.7-1.7C8.3,5.1,8.6,5,8.8,5h6.3c0.3,0,0.5,0.1,0.7,0.3L17.6,7H6.4z"
    },
    shopping_cart: {
        id: "shopping-cart", viewBox: "0 0 24 24",
        path: "M21.2,8.9l-3.3,6.6C17.7,15.8,17.4,16,17,16H8c-0.5,0-0.9-0.3-1-0.7L4.2,5H3C2.4,5,2,4.6,2,4s0.4-1,1-1h2c0.5,0,0.9,0.3,1,0.7L6.6,6h12.8c0.7,0,1.3,0.4,1.7,1C21.4,7.6,21.5,8.3,21.2,8.9z M7.5,18C6.7,18,6,18.7,6,19.5S6.7,21,7.5,21S9,20.3,9,19.5S8.3,18,7.5,18z M17.5,18c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S18.3,18,17.5,18z"
    },
    shuffle: {
        id: "shuffle", viewBox: "0 0 24 24",
        path: "M18,7.4L5.7,19.7c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4l6.3-6.3L4.8,6.2c-0.3-0.4-0.3-0.9,0-1.3c0.4-0.4,1-0.5,1.4-0.1l5.8,5.8L16.6,6h-1.9c-0.6,0-1-0.4-1-1s0.4-1,1-1H19c0.6,0,1,0.4,1,1v4.3c0,0.6-0.4,1-1,1s-1-0.4-1-1V7.4z M20,18.9v-4.2c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.9l-2.8-2.8c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l2.8,2.8h-1.9c-0.6,0-1,0.4-1,1s0.4,1,1,1H19c0.6,0,1-0.4,1-1C20,19,20,18.9,20,18.9z"
    },
    shuffle_2: {
        id: "shuffle-2", viewBox: "0 0 24 24",
        path: "M20.7,16.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7l-2,2C18.5,19.9,18.3,20,18,20c-0.3,0-0.5-0.1-0.7-0.3c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4l0.3-0.3H16c-2,0-3.9-1-5-2.7C9.9,17,8,18,6,18H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c2.2,0,4-1.8,4-4S8.2,8,6,8H4C3.4,8,3,7.6,3,7s0.4-1,1-1h2c2,0,3.9,1,5,2.7C12.1,7,14,6,16,6h1.6l-0.3-0.3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2,2C20.9,6.5,21,6.7,21,7c0,0.3-0.1,0.5-0.3,0.7l-2,2C18.5,9.9,18.3,10,18,10c-0.3,0-0.5-0.1-0.7-0.3c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4L17.6,8H16c-2.2,0-4,1.8-4,4s1.8,4,4,4h1.6l-0.3-0.3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0L20.7,16.3z"
    },
    skip_back: {
        id: "skip-back", viewBox: "0 0 24 24",
        path: "M17.5,7.8v8.4c0,0.7-0.4,1.3-1,1.6c-0.3,0.1-0.6,0.2-0.9,0.2c-0.5,0-0.9-0.2-1.3-0.5l-5.1-4.2L9,13.2V17c0,0.6-0.4,1-1,1s-1-0.4-1-1V7c0-0.6,0.4-1,1-1s1,0.4,1,1v3.8l0.1-0.2l5.1-4.2c0.6-0.5,1.5-0.6,2.2-0.3C17.1,6.5,17.5,7.1,17.5,7.8z"
    },
    skip_forward: {
        id: "skip-forward", viewBox: "0 0 24 24",
        path: "M17,7v10c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1v-3.8l-0.1,0.2l-5.1,4.2C9.4,17.8,8.9,18,8.5,18c-0.3,0-0.6-0.1-0.9-0.2c-0.6-0.3-1-0.9-1-1.6V7.8c0-0.7,0.4-1.3,1-1.6C8.3,5.9,9.1,6,9.8,6.5l5.1,4.2l0.1,0.2V7c0-0.6,0.4-1,1-1S17,6.4,17,7z"
    },
    slash: {
        id: "slash", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8c0-1.8,0.6-3.5,1.7-4.9l11.2,11.2C15.5,19.4,13.8,20,12,20z M18.3,16.9L7.1,5.7C8.5,4.6,10.2,4,12,4c4.4,0,8,3.6,8,8C20,13.8,19.4,15.5,18.3,16.9z"
    },
    smartphone: {
        id: "smartphone", viewBox: "0 0 24 24",
        path: "M17,2H7C5.3,2,4,3.3,4,5v14c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3V5C20,3.3,18.7,2,17,2z M12,18c-0.8,0-1.5-0.7-1.5-1.5S11.2,15,12,15s1.5,0.7,1.5,1.5S12.8,18,12,18z M14.5,8h-5c-0.6,0-1-0.4-1-1s0.4-1,1-1h5c0.6,0,1,0.4,1,1S15.1,8,14.5,8z"
    },
    speaker: {
        id: "speaker", viewBox: "0 0 24 24",
        path: "M13.5,15.5c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5S11.2,14,12,14S13.5,14.7,13.5,15.5z M12,9c0.6,0,1-0.4,1-1s-0.4-1-1-1s-1,0.4-1,1S11.4,9,12,9z M20,5v14c0,1.7-1.3,3-3,3H7c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h10C18.7,2,20,3.3,20,5z M9,8c0,1.7,1.3,3,3,3s3-1.3,3-3s-1.3-3-3-3S9,6.3,9,8z M15.5,15.5c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5S10.1,19,12,19S15.5,17.4,15.5,15.5z"
    },
    square: {
        id: "square", viewBox: "0 0 24 24",
        path: "M18,3H6C4.3,3,3,4.3,3,6v12c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V6C21,4.3,19.7,3,18,3z M19,18c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1V6c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1V18z"
    },
    star: {
        id: "star", viewBox: "0 0 24 24",
        path: "M21.7,10.2l-4.1,4l1,5.6c0.1,0.4-0.1,0.8-0.4,1C18,20.9,17.8,21,17.6,21c-0.2,0-0.3,0-0.5-0.1L12,18.2l-5.1,2.7c-0.5,0.3-1.1,0.1-1.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.6l1-5.6l-4.1-4C2.1,9.9,2,9.6,2.1,9.2c0.1-0.4,0.4-0.6,0.8-0.7l5.7-0.8l2.5-5.1c0.2-0.5,0.8-0.7,1.3-0.5c0.2,0.1,0.4,0.3,0.5,0.5l2.5,5.1l5.7,0.8c0.4,0.1,0.7,0.3,0.8,0.7C22.1,9.5,22,9.9,21.7,10.2z"
    },
    stop_circle: {
        id: "stop-circle", viewBox: "0 0 24 24",
        path: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16,14.8c0,0.7-0.6,1.3-1.3,1.3H9.3C8.6,16,8,15.4,8,14.8V9.3C8,8.6,8.6,8,9.3,8h5.5C15.4,8,16,8.6,16,9.3V14.8z M10,10h4v4h-4V10z"
    },
    sun: {
        id: "sun", viewBox: "0 0 24 24",
        path: "M11,5V3c0-0.6,0.4-1,1-1s1,0.4,1,1v2c0,0.6-0.4,1-1,1S11,5.6,11,5z M21,11h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1-0.4,1-1S21.6,11,21,11z M6,12c0-0.6-0.4-1-1-1H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h2C5.6,13,6,12.6,6,12z M6.3,7.9C6.5,8,6.7,8.1,7,8.1c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L6.2,5c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L6.3,7.9z M17,8.1c0.3,0,0.5-0.1,0.7-0.3l1.4-1.4c0.4-0.4,0.4-1,0-1.4c-0.4-0.3-0.9-0.4-1.3-0.1l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4C16.5,8,16.7,8.1,17,8.1z M12,18c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C13,18.4,12.6,18,12,18z M19.2,17.6l-1.5-1.4c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l1.4,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3C19.6,18.6,19.6,18,19.2,17.6C19.2,17.6,19.2,17.6,19.2,17.6z M6.3,16.2L6.3,16.2l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l1.4-1.4c0.4-0.4,0.4-1,0-1.4S6.7,15.8,6.3,16.2z M12,8c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,8,12,8z"
    },
    swap: {
        id: "swap", viewBox: "0 0 24 24",
        path: "M3,8c0-0.6,0.4-1,1-1h13.1l-1.6-1.2c-0.4-0.3-0.5-1-0.2-1.4c0.3-0.4,1-0.5,1.4-0.2l3.9,3c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.4,0.3,1.1-0.2,1.4l-4,3C16.4,11.9,16.2,12,16,12c-0.3,0-0.6-0.1-0.8-0.4c-0.3-0.4-0.2-1.1,0.2-1.4L17,9H4C3.4,9,3,8.6,3,8zM20,16H7l1.6-1.2c0.4-0.3,0.5-1,0.2-1.4s-1-0.5-1.4-0.2l-4,3c-0.4,0.3-0.5,1-0.2,1.4c0.1,0.1,0.1,0.1,0.2,0.2l3.9,3C7.4,20.9,7.6,21,7.9,21c0.3,0,0.6-0.1,0.8-0.4c0.3-0.4,0.3-1.1-0.2-1.4L6.9,18H20c0.6,0,1-0.4,1-1S20.6,16,20,16z"
    },
    sync: {
        id: "sync", viewBox: "0 0 24 24",
        path: "M22.5,6.2l-0.8,4c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2l-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1C21,11,20.9,11,20.8,11h-0.2l-4.3-0.7c-0.6-0.1-0.9-0.6-0.8-1.2c0.1-0.6,0.6-0.9,1.2-0.8l1.7,0.3C17,6.4,14.6,5,12.1,5C9.1,4.9,6.5,6.7,5.4,9.4c0,0,0,0,0,0C5.2,9.9,4.6,10.2,4.1,10C3.6,9.8,3.3,9.2,3.5,8.7c0.8-1.9,2.2-3.5,4.1-4.5c4.5-2.4,10.1-0.8,12.5,3.7l0.4-2c0.1-0.6,0.6-0.9,1.2-0.8C22.2,5.1,22.6,5.6,22.5,6.2z M19.9,14.1c-0.5-0.2-1.1,0-1.3,0.5c0,0,0,0,0,0c-1.1,2.7-3.7,4.4-6.6,4.4c-2.6,0-5-1.4-6.2-3.6l1.7,0.3h0.2c0.6,0,1-0.4,1.1-0.9c0-0.6-0.4-1-0.9-1.1L3.4,13c-0.1,0-0.2,0-0.3,0H3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2l-0.8,4c-0.1,0.5,0.2,1.1,0.7,1.2c0,0,0,0,0.1,0h0.2c0.5,0,0.9-0.3,1-0.8l0.4-2c2.4,4.5,8,6.1,12.5,3.7c1.8-1,3.3-2.6,4.1-4.5C20.6,14.8,20.4,14.3,19.9,14.1z"
    },

    text: {
        id: "text", viewBox: "0 0 24 24",
        path: "M21,5v3c0,0.6-0.4,1-1,1s-1-0.4-1-1V6h-6v13h2c0.6,0,1,0.4,1,1s-0.4,1-1,1H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h2V6H5v2c0,0.6-0.4,1-1,1S3,8.6,3,8V5c0-0.6,0.4-1,1-1h16C20.6,4,21,4.4,21,5z"
    },
    thermometer: {
        id: "thermometer", viewBox: "0 0 24 24",
        path: "M15,13V5c0-1.7-1.3-3-3-3S9,3.3,9,5v8c-1.3,0.9-2,2.4-2,4c0,2.8,2.2,5,5,5c1.6,0,3.1-0.7,4-2C17.7,17.8,17.2,14.7,15,13zM13,9.5h-2V5c0-0.6,0.4-1,1-1c0.3,0,0.5,0.1,0.7,0.3C12.9,4.5,13,4.7,13,5V9.5z"
    },
    thermometer_minus: {
        id: "thermometer-minus", viewBox: "0 0 24 24",
        path: "M8,6c0,0.6-0.4,1-1,1H3C2.4,7,2,6.6,2,6s0.4-1,1-1h4C7.6,5,8,5.4,8,6z M18,20c-0.9,1.3-2.4,2-4,2c-2.8,0-5-2.2-5-5c0-1.6,0.7-3.1,2-4V5c0-1.7,1.3-3,3-3s3,1.3,3,3v8C19.2,14.7,19.7,17.8,18,20z M15,5c0-0.3-0.1-0.5-0.3-0.7C14.5,4.1,14.3,4,14,4c-0.6,0-1,0.4-1,1v4.5h2V5z"
    },
    thermometer_plus: {
        id: "thermometer-plus", viewBox: "0 0 24 24",
        path: "M8,6c0,0.6-0.4,1-1,1H6v1c0,0.6-0.4,1-1,1S4,8.6,4,8V7H3C2.4,7,2,6.6,2,6s0.4-1,1-1h1V4c0-0.6,0.4-1,1-1s1,0.4,1,1v1h1C7.6,5,8,5.4,8,6z M18,20c-0.9,1.3-2.4,2-4,2c-2.8,0-5-2.2-5-5c0-1.6,0.7-3.1,2-4V5c0-1.7,1.3-3,3-3s3,1.3,3,3v8C19.2,14.7,19.7,17.8,18,20z M15,5c0-0.3-0.1-0.5-0.3-0.7C14.5,4.1,14.3,4,14,4c-0.6,0-1,0.4-1,1v4.5h2V5z"
    },
    toggle_left: {
        id: "toggle-left", viewBox: "0 0 24 24",
        path: "M15,5H9c-3.9,0-7,3.1-7,7s3.1,7,7,7h6c3.9,0,7-3.1,7-7S18.9,5,15,5z M9,15c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S10.7,15,9,15z M10,12c0,0.6-0.4,1-1,1s-1-0.4-1-1s0.4-1,1-1S10,11.4,10,12z"
    },
    toggle_right: {
        id: "toggle-right", viewBox: "0 0 24 24",
        path: "M16,12c0,0.6-0.4,1-1,1s-1-0.4-1-1s0.4-1,1-1S16,11.4,16,12z M22,12c0,3.9-3.1,7-7,7H9c-3.9,0-7-3.1-7-7s3.1-7,7-7h6C18.9,5,22,8.1,22,12z M18,12c0-1.7-1.3-3-3-3s-3,1.3-3,3s1.3,3,3,3S18,13.7,18,12z"
    },
    trash: {
        id: "trash", viewBox: "0 0 24 24",
        path: "M21,6h-5V4.3C16,3,14.8,2,13.5,2h-3C9.2,2,8,3,8,4.3V6H3C2.4,6,2,6.4,2,7s0.4,1,1,1h1v11c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3V8h1c0.6,0,1-0.4,1-1S21.6,6,21,6z M10,16c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4c0-0.6,0.4-1,1-1s1,0.4,1,1V16z M10,4.3C10,4.2,10.2,4,10.5,4h3C13.8,4,14,4.2,14,4.3V6h-4V4.3z M16,16c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4c0-0.6,0.4-1,1-1s1,0.4,1,1V16z"
    },
    trash_2: {
        id: "trash-2", viewBox: "0 0 24 24",
        path: "M21,6h-5V4.3C16,3,14.8,2,13.5,2h-3C9.2,2,8,3,8,4.3V6H3C2.4,6,2,6.4,2,7s0.4,1,1,1h1v11c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3V8h1c0.6,0,1-0.4,1-1S21.6,6,21,6z M14,6h-4V4.3C10,4.2,10.2,4,10.5,4h3C13.8,4,14,4.2,14,4.3V6z"
    },
    trending_down: {
        id: "trending-down", viewBox: "0 0 24 24",
        path: "M21,12v5c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1l-0.1,0.1C20.2,18,20.1,18,20,18h-5c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.8l-4-4.7l-4.3,2.6c-0.4,0.3-1,0.2-1.3-0.2l-5-6C2.9,7.2,2.9,6.6,3.4,6.2c0.4-0.4,1.1-0.3,1.4,0.1l4.4,5.3l4.3-2.6c0.4-0.2,1-0.2,1.3,0.2l4.2,5V12c0-0.6,0.4-1,1-1S21,11.4,21,12z"
    },
    trending_up: {
        id: "trending-ip", viewBox: "0 0 24 24",
        path: "M21,6.8c0,0.1,0,0.1,0,0.2v5c0,0.6-0.4,1-1,1s-1-0.4-1-1V9.7l-4.2,5c-0.3,0.4-0.9,0.5-1.3,0.2l-4.3-2.6l-4.4,5.3C4.6,17.9,4.3,18,4,18c-0.2,0-0.5-0.1-0.6-0.2c0,0,0,0,0,0c-0.4-0.4-0.5-1-0.1-1.4l5-6c0.3-0.4,0.9-0.5,1.3-0.2l4.3,2.6l4-4.7H15c-0.6,0-1-0.4-1-1s0.4-1,1-1h5c0.1,0,0.1,0.1,0.2,0.1h0.2c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c0.1,0,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1C21,6.7,21,6.7,21,6.8z"
    },
    tv: {
        id: "tv", viewBox: "0 0 24 24",
        path: "M18,6h-3.6l2.3-2.3c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L12,5.6L8.7,2.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L9.6,6H6C4.3,6,3,7.3,3,9v10c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V9C21,7.3,19.7,6,18,6z M19,19c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1v-7c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1V19z"
    },
    twitter: {
        id: "tv", viewBox: "0 0 24 24",
        path: "M21,6.2c-0.3,1-0.8,2-1.5,2.8c0,0.1,0,0.2,0,0.3C19.3,15.4,14.2,20.2,8.1,20c-1.7,0-3.3-0.4-4.8-1.2C3.1,18.8,3,18.7,3,18.5C3,18.3,3.1,18,3.4,18c1.7-0.2,3.3-1,4.5-2.3c-3.5-2.1-5.3-6.1-4.6-10c0-0.1,0.1-0.2,0.1-0.3c0.2-0.2,0.4-0.1,0.6,0C5.8,7.6,8.1,9.2,11.5,9c-0.3-1.4,0.1-2.9,1.2-3.9c0,0,0.1-0.1,0.1-0.1c1.6-1.4,4-1.3,5.5,0.2c0.6,0.6,1.4,0.7,2.2,0.4c0.1,0,0.2-0.1,0.3,0C20.9,5.7,21.1,5.9,21,6.2z"
    },

    umbrella: {
        id: "umbrella", viewBox: "0 0 24 24",
        path: "M22,12c0,0.6-0.4,1-1,1h-8v6c0,0.6,0.4,1,1,1s1-0.4,1-1s0.4-1,1-1s1,0.4,1,1c0,1.7-1.3,3-3,3s-3-1.3-3-3v-6H3c-0.6,0-1-0.4-1-1C2,6.5,6.5,2,12,2S22,6.5,22,12z"
    },
    undo: {
        id: "undo", viewBox: "0 0 24 24",
        path: "M20.2,21C20.2,21,20.2,21,20.2,21C20.2,21,20.2,21,20.2,21L20.2,21z M11.5,7V5.8c0-0.7-0.4-1.4-1.1-1.6C9.6,3.8,8.8,4,8.2,4.5L3.1,8.9C3,9,3,9,2.9,9.1C2.3,9.8,2.4,11,3.1,11.6L8.2,16c0.6,0.5,1.4,0.6,2.2,0.3c0.7-0.3,1.1-0.9,1.1-1.6v-1.1c3.8,0.4,6.9,3,7.8,6.7c0.1,0.5,0.5,0.8,1,0.8c0.5,0,0.9-0.3,1-0.8c0.2-0.8,0.3-1.7,0.3-2.5C21.4,12.1,17.1,7.4,11.5,7z"
    },
    unlock: {
        id: "unlock", viewBox: "0 0 24 24",
        path: "M13,15c0,0.6-0.4,1-1,1s-1-0.4-1-1s0.4-1,1-1S13,14.4,13,15z M20,11v8c0,1.7-1.3,3-3,3H7c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h1V6c0-2.2,1.8-4,4-4s4,1.8,4,4c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-1.1-0.9-2-2-2s-2,0.9-2,2v2h7C18.7,8,20,9.3,20,11zM15,15c0-1.7-1.3-3-3-3s-3,1.3-3,3s1.3,3,3,3S15,16.7,15,15z"
    },
    upload: {
        id: "upload", viewBox: "0 0 24 24",
        path: "M20,5v2c0,0.6-0.4,1-1,1s-1-0.4-1-1V6H6v1c0,0.6-0.4,1-1,1S4,7.6,4,7V5c0-0.6,0.4-1,1-1h14C19.6,4,20,4.4,20,5z M12.6,9.2c-0.4-0.3-0.8-0.3-1.2,0l-4,3c-0.4,0.3-0.5,1-0.2,1.4C7.4,13.9,7.7,14,8,14c0.2,0,0.4-0.1,0.6-0.2L11,12c0,0,0,0,0,0v8c0,0.6,0.4,1,1,1s1-0.4,1-1v-8c0,0,0,0,0-0.1l2.4,1.7c0,0,0,0,0,0c0.5,0.3,1.1,0.2,1.4-0.2c0.3-0.5,0.2-1.1-0.2-1.4L12.6,9.2z"
    },

    video: {
        id: "video", viewBox: "0 0 24 24",
        path: "M22,8.6v6.7c0,0.7-0.4,1.2-1,1.5c-0.2,0.1-0.5,0.1-0.7,0.2c-0.4,0-0.8-0.2-1.2-0.5l-2.2-2V16c0,1.7-1.3,3-3,3H5c-1.7,0-3-1.3-3-3V8c0-1.7,1.3-3,3-3h9c1.7,0,3,1.3,3,3v1.5l2.2-2C19.7,7,20.4,6.9,21,7.2C21.6,7.4,22,8,22,8.6z"
    },
    video_off: {
        id: "video-off", viewBox: "0 0 24 24",
        path: "M14.2,17l1.4,1.4C15.2,18.8,14.6,19,14,19H5c-1.7,0-3-1.3-3-3V8c0-0.8,0.4-1.6,1-2.2L3.1,6l1.8,1.7L14.2,17z M21,7.2c-0.6-0.3-1.3-0.2-1.8,0.3l-2.2,2V8c0-1.7-1.3-3-3-3H7.8l1.3,1.3l6.6,6.6l2,2l2,2c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c0.6-0.2,1-0.8,1-1.5V8.6C22,8,21.6,7.4,21,7.2z M20.7,19.3L17,15.6l-2-2L8.4,7l-2-2L4.7,3.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l0.5,0.5L5.6,7l9.3,9.3l1.5,1.5l2.9,2.9c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3z"
    },

    volume_down: {
        id: "volume-down", viewBox: "0 0 24 24",
        path: "M20.8,15.6C20.6,15.9,20.3,16,20,16c-0.2,0-0.5-0.1-0.6-0.2c-0.4-0.3-0.5-1-0.1-1.4c1-1.4,1-3.3,0-4.7c-0.3-0.4-0.3-1.1,0.1-1.4c0.4-0.3,1.1-0.3,1.4,0.2C22.4,10.5,22.4,13.5,20.8,15.6z M16.5,3.1c-0.3-0.2-0.7-0.2-1,0L9,7.6H4c-0.6,0-1,0.4-1,1v6.9c0,0.6,0.4,1,1,1h5l6.4,4.4c0.2,0.1,0.4,0.2,0.6,0.2c0.6,0,1-0.4,1-1V4C17,3.6,16.8,3.3,16.5,3.1z"
    },
    volume_mute: {
        id: "volume-mute", viewBox: "0 0 24 24",
        path: "M18,4v16c0,0.6-0.4,1-1,1c-0.2,0-0.4-0.1-0.6-0.2L10,16.4H5c-0.6,0-1-0.4-1-1V8.6c0-0.6,0.4-1,1-1h5l6.4-4.4c0.4-0.3,1.1-0.2,1.4,0.2C17.9,3.6,18,3.8,18,4z"
    },
    volume_off: {
        id: "volume-off", viewBox: "0 0 24 24",
        path: "M16.7,9.6c-0.3-0.4-0.3-1.1,0.1-1.4c0.4-0.3,1.1-0.3,1.4,0.2c1.6,2.1,1.6,5,0.1,7.2l-1.4-1.4C17.8,12.7,17.7,10.9,16.7,9.6zM21,12c0,1.6-0.7,3.2-1.8,4.4l1.4,1.4c1.5-1.6,2.3-3.6,2.4-5.8c0-2.6-1.3-5.1-3.4-6.8c-0.4-0.4-1.1-0.3-1.4,0.1c-0.4,0.4-0.3,1.1,0.1,1.4C20,8,21,9.9,21,12z M15,4c0-0.6-0.4-1-1-1c-0.2,0-0.4,0.1-0.6,0.2L9,6.2l6,6V4z M2,7.6c-0.6,0-1,0.4-1,1v6.9c0,0.6,0.4,1,1,1h5l6.4,4.4c0.2,0.1,0.4,0.2,0.6,0.2c0.6,0,1-0.4,1-1v-2.2L4.7,7.6H2z M20.7,19.3l-16-16c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l16,16c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3z"
    },
    volume_up: {
        id: "volume-up", viewBox: "0 0 24 24",
        path: "M18.3,15.6c-0.2,0.2-0.5,0.4-0.8,0.4c-0.2,0-0.5-0.1-0.6-0.2c-0.4-0.3-0.5-1-0.1-1.4c1-1.4,1-3.3,0-4.7c-0.3-0.4-0.3-1.1,0.1-1.4c0.4-0.3,1.1-0.3,1.4,0.2C19.9,10.5,19.9,13.5,18.3,15.6z M19.6,5.2c-0.4-0.4-1.1-0.3-1.4,0.1c-0.4,0.4-0.3,1.1,0.1,1.4C20,8,21,9.9,21,12c0,2.1-1,4-2.6,5.2c-0.4,0.4-0.5,1-0.1,1.4c0,0,0,0,0,0c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2c2.1-1.6,3.3-4.1,3.4-6.8C23,9.4,21.7,6.9,19.6,5.2z M14.5,3.1c-0.3-0.2-0.7-0.2-1,0L7,7.6H2c-0.6,0-1,0.4-1,1v6.9c0,0.6,0.4,1,1,1h5l6.4,4.4c0.2,0.1,0.4,0.2,0.6,0.2c0.6,0,1-0.4,1-1V4C15,3.6,14.8,3.3,14.5,3.1z"
    },

    wifi: {
        id: "wifi", viewBox: "0 0 24 24",
        path: "M13,19c0,0.6-0.4,1-1,1s-1-0.4-1-1s0.4-1,1-1S13,18.4,13,19z M12,14c-1.3,0-2.5,0.5-3.5,1.4c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0c1.2-1.1,3-1.1,4.2,0c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4C14.5,14.5,13.3,14,12,14z M12,9c-2.4,0-4.8,1-6.5,2.8c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0c2.7-2.8,7.1-2.9,9.9-0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.4,0.3,0.7,0.3c0.6,0,1-0.5,1-1c0-0.3-0.1-0.5-0.3-0.7C16.8,10,14.5,9,12,9z M21.7,7.9c-5.4-5.2-14-5.2-19.4,0C1.9,8.3,1.9,9,2.4,9.3c0.4,0.3,0.9,0.3,1.3,0c4.7-4.5,12-4.5,16.7,0c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3C22.1,8.9,22.1,8.3,21.7,7.9z"
    },
    wifi_off: {
        id: "wifi-off", viewBox: "0 0 24 24",
        path: "M13,19c0,0.6-0.4,1-1,1s-1-0.4-1-1s0.4-1,1-1S13,18.4,13,19z M20.7,19.3L12.4,11l-1.9-1.9L8.1,6.7L6.5,5.1L4.7,3.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4l1.5,1.5l2.2,2.2l1.5,1.5l2.7,2.7l2.8,2.8l3.8,3.9c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C21.1,20.3,21.1,19.7,20.7,19.3C20.7,19.3,20.7,19.3,20.7,19.3z M21.7,7.9C19.1,5.4,15.6,4,12,4c-1.5,0-3,0.3-4.4,0.7l1.6,1.6c4-1,8.2,0.1,11.2,3c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3C22.1,8.9,22.1,8.3,21.7,7.9z M3.8,6.7C3.3,7,2.8,7.5,2.3,7.9C1.9,8.3,1.9,9,2.4,9.3c0.4,0.3,0.9,0.3,1.3,0c0.5-0.5,1-0.9,1.6-1.3L3.8,6.7z M17,13.1c0.2,0.2,0.4,0.3,0.7,0.3c0.6,0,1-0.5,1-1c0-0.3-0.1-0.5-0.3-0.7C16.7,10,14.4,9,12,9h-0.2l2.3,2.3C15.3,11.7,16.2,12.3,17,13.1zM7.4,10.3c-0.7,0.4-1.3,0.9-1.9,1.5c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0c0.6-0.6,1.3-1.1,2-1.4L7.4,10.3z M8.5,15.4c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0c1.1-1,2.7-1.1,3.8-0.3l-2.5-2.5C10.2,14.2,9.3,14.7,8.5,15.4z"
    },

};