import React, {useState, useContext} from "react";
import {Icon} from "../Framework/ui/Icons/Icons";
import {evaIconsFill} from "../Framework/ui/Icons/iconsEvaFill";
import {State} from "../State";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const Header = ({siteTitle}) => {
    const appState = useContext(State);
    const isMobile = appState.isMobile;

    const [isVisible, setIsVisible] = useState(isMobile);
    return (
        <header className={isMobile ? "header-mobile" : "header"}>
            <AniLink activeClassName={"active"}
                     cover
                     bg={"#1d1a16"}
                     direction="right"
                     duration={1}
                     className={"brand"}
                     to={"/"}>
                <Logo size={isMobile ? 30 : 40}/>
            </AniLink>

            {isMobile ? <Icon className={"nav-burger"} onClick={() => setIsVisible(!isVisible)} size={"md"} icon={evaIconsFill.menu_2}/> : null}
            <nav className={isVisible ? "open" : ""}>
                <ul>
                    <MenuItem onClick={() => setIsVisible(false)} path={"/"} label={"Home"} direction={"right"}/>
                    {/*<MenuItem path={"/videos"} label={"Videos"}/>*/}
                    <MenuItem onClick={() => setIsVisible(false)} path={"/about"} label={"About"}/>
                    <MenuItem onClick={() => setIsVisible(false)} path={"/contact"} label={"Contact"}/>
                </ul>
            </nav>
        </header>
    )
};

const MenuItem = ({path, label, onClick, direction = "left"}) => (
    <li>
        <AniLink onClick={onClick}
                 activeClassName={"current"}
                 to={path}
                 cover
                 bg={"#1d1a16"}
                 direction={direction}
                 duration={1}
        >
            {label}
        </AniLink></li>
);

export const Logo = ({size, className}) => {
    return (

        <svg className={className} style={{display: "inline-flex"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 278" height={size}>
            <path className="sc-1"
                  d="M231.3,243.26c-24.5,21.7-56.72,34.89-92.02,34.89c-79.74,0-143.78-67.2-138.59-148.05 C5.15,60.4,62.25,4.12,132,0.56c37.44-1.91,71.81,11.03,97.81,33.43c3.91,3.37,1.58,9.79-3.58,9.79c-1.32,0-2.57-0.48-3.57-1.34 c-22.41-19.32-51.55-31.04-83.39-31.04c-75.34,0-135.68,65.5-127.04,142.55C18.87,213.21,67,260.63,126.35,266.49 c37.5,3.7,72.14-8.92,97.69-31.59c1-0.89,2.28-1.39,3.62-1.39C232.78,233.51,235.14,239.85,231.3,243.26z M476.34,124.16 c-7.02-47.63-46.68-84.87-94.68-88.55c-24.32-1.86-47.02,4.68-65.56,16.99c-3.48,2.31-3.38,7.48,0.14,9.71 c1.96,1.24,4.43,1.15,6.36-0.13c14.61-9.7,32.08-15.41,50.89-15.41c50.99,0,92.48,41.48,92.48,92.48c0,4.52-0.36,8.95-1.01,13.29 c-0.34,2.28,0.63,4.56,2.58,5.79c3.5,2.22,8.19,0.13,8.79-3.97C477.77,144.69,477.88,134.57,476.34,124.16z"/>
            <path className="sc-2"
                  d="M373.5,0.37c-76.71,0-138.89,62.18-138.89,138.89c0,76.71,62.18,138.89,138.89,138.89 s138.89-62.18,138.89-138.89C512.38,62.55,450.2,0.37,373.5,0.37z M373.5,267.13c-70.51,0-127.87-57.36-127.87-127.87 S302.99,11.39,373.5,11.39s127.87,57.36,127.87,127.87S444,267.13,373.5,267.13z M196.67,225.91 c-18.54,12.31-41.24,18.85-65.56,16.99c-48-3.67-87.67-40.92-94.68-88.55c-1.53-10.4-1.43-20.52,0-30.21 c0.61-4.1,5.29-6.19,8.79-3.97c1.95,1.24,2.92,3.51,2.58,5.79c-0.65,4.34-1.01,8.77-1.01,13.29c0,50.99,41.49,92.47,92.48,92.47 c18.81,0,36.28-5.71,50.89-15.41c1.93-1.28,4.41-1.37,6.36-0.13C200.05,218.43,200.15,223.6,196.67,225.91z"/>
        </svg>

    )
};
