import React from 'react';
import PropTypes from "prop-types";
import {Margin, StringUtils} from "../../Utilities";
import styles from "./icons.module.scss";

export const Icon = ({icon, className, onClick, value, size = "sm", height, color, customColor, m, ml, mr, mx, mt, mb, my,}) => {
    let cls = StringUtils.joinIgnoreEmpty(" ", "icon", "icon-" + icon.id, styles[color], className);
    cls += Margin({m, ml, mr, mx, mt, mb, my});

    if (!height) {
        if (size === 'xs') {
            height = "20";
        } else if (size === 'sm') {
            height = "25";
        } else if (size === 'md') {
            height = "32";
        } else if (size === 'lg') {
            height = "64";
        } else if (size === 'xl') {
            height = "100";
        }
    }

    return (
        <svg className={cls}
             values={value}
             onClick={onClick}
             xmlns="http://www.w3.org/2000/svg"
             viewBox={icon.viewBox}
             height={height}
             fill={customColor}>
            <path values={value} onClick={onClick} d={icon.path}/>
        </svg>
    );
};

Icon.propTypes = {
    icon: PropTypes.any,
    className: PropTypes.string,
    width: PropTypes.number,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    color: PropTypes.oneOf(["primary", "secondary", "danger", "success", "warning", "white", "black"]),
    customColor: PropTypes.string
};
