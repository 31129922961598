import React from "react";
import PropTypes from "prop-types";

export const Footer = ({copyright}) => {
    return (
        <footer>
            © {copyright} {new Date().getFullYear()}
        </footer>
    )
};

Footer.propTypes = {
    copyright: PropTypes.string
};
