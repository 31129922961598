import React from "react";
import {CheckForScreens} from "./Framework/Utilities";

export const State = React.createContext(null);

export const StateProvider = ({children,}) => {
    const [isMobile, screen] = CheckForScreens();

    return (
        <State.Provider value={{
            isMobile: isMobile,
            screen: screen,
        }}>
            {children}
        </State.Provider>
    )
};
