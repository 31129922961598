import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"

import {Header} from "./Header"
import {Footer} from "./Footer";
import "./layout.scss"


const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title}/>
            <main>{children}</main>
            <Footer copyright={data.site.siteMetadata.title}/>
        </>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
